export const resetState = () => {
  return {
    date: null,
    batch: "",
    sku: "",
    briefType: null,
    size: null,
    rndTeam: null,
    rnd: null,
    designer: null,
    status: [1, 2, 12, 22],
    sizeValue: null,
    rndName: null,
    designerName: null,
    statusValue: null,
    dateValue: null,
    briefTypes: [],
    rnds: [],
    designers: [],
    archive: null,
  };
};
