export const resetState = () => {
  return {
    date: null,
    batch: "",
    sku: "",
    briefType: null,
    size: null,
    rndTeam: null,
    rnd: null,
    epm: null,
    designer: null,
    status: [2, 3, 12, 13],
    sizeValue: null,
    rndName: null,
    designerName: null,
    epmName: null,
    statusValue: null,
    dateValue: null,
    briefTypes: [],
    rndTeams: [],
    rnds: [],
    designers: [],
    epms: [],
    archive: null,
  };
};
