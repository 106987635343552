import {
  ActionIcon,
  Box,
  Group,
  Image,
  ScrollArea,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { IconCheckbox } from "@tabler/icons-react";
import { find, map } from "lodash";
import { useState } from "react";
import { showNotification } from "../../../utils/index";
import { postService } from "../../../services";

const headCellStyles = {
  fontWeight: "bold",
  color: "#000000",
  padding: 0,
  backgroundColor: "#e1eaff",
  height: "70px",
  borderRight: "1px solid #d1d6e3",
  textAlign: "center",
  verticalAlign: "middle",
};

const ModalAdLinks = ({ close, data, setData, adLinks, fetchBriefs }) => {
  const [loading, setLoading] = useState(false);
  const handleUpdateMktPost = async ({
    briefId,
    uid,
    data,
    newValue,
    newThumbLink,
  }) => {
    setLoading(true);
    const updatePostCTAResponse = await postService.updateMktPost({
      briefId,
      uid,
      data,
    });
    if (updatePostCTAResponse) {
      setData((prev) => {
        return map(prev, (x) => {
          const type = x?.type;
          if (x?.uid === uid) {
            return {
              ...x,
              ...(type === "video" &&
                newThumbLink && { thumbLink: newThumbLink }),
              ...(type === "video" && newValue && { videoLink: newValue }),
              ...((type === "image" || !type) &&
                newValue && { image: newValue }),
            };
          }
          return x;
        });
      });
      // fetchBriefs();
    }
    setLoading(false);
    close();
  };
  const rows = (
    <Table.Tr key={data.uid}>
      <Table.Td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {data?.type === "image" || !data?.type ? "Image" : "Video"}
        </Text>
      </Table.Td>
      <Table.Td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <Image
          radius="md"
          src={
            data?.type === "image" || !data?.type
              ? data?.image
              : data?.thumbLink
          }
          height={100}
          fit="contain"
        />
      </Table.Td>
      <Table.Td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <TextInput
          value={data?.newValue}
          defaultValue={
            data?.type === "image" || !data?.type
              ? data?.image
              : data?.videoLink
          }
          styles={{
            label: {
              fontSize: "12px",
              fontWeight: "bold",
              textAlign: "left",
            },
          }}
          label={
            data?.type === "image" || !data?.type ? "Link ảnh" : "Link video"
          }
          onChange={(event) => {
            const newValue = event.target.value;
            setData((prev) =>
              prev.map((i) => {
                if (i?.uid === data?.uid) {
                  return {
                    ...i,
                    newValue: newValue,
                  };
                }
                return i;
              })
            );
          }}
        />
        {data?.type === "video" && (
          <TextInput
            defaultValue={data?.thumbLink}
            value={data?.newThumbLink}
            label="Link thumb"
            styles={{
              label: {
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "left",
              },
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setData((prev) =>
                prev.map((i) => {
                  if (i?.uid === data?.uid) {
                    return {
                      ...i,
                      newThumbLink: newValue,
                    };
                  }
                  return i;
                })
              );
            }}
          />
        )}
      </Table.Td>
      <Table.Td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <Group
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActionIcon
            variant="filled"
            aria-label="Settings"
            color="green"
            loading={loading}
            onClick={() => {
              const uid = data?.uid;
              const foundPayload = find(adLinks, { uid });
              const type = foundPayload?.type;
              const isVideo = type === "video";
              const { newValue, newThumbLink, thumbLink } = foundPayload;
              if (!newValue && !newThumbLink) {
                showNotification(
                  "Thất bại",
                  "Có vẻ như bạn chưa thay đổi gì mới",
                  "red"
                );
                return;
              }
              let payload = {};
              if (isVideo) {
                payload = {
                  action: "update",
                  updatePayload: {
                    ...(newValue && { newLink: newValue }),
                    ...(newThumbLink && {
                      videoThumbLinks: [
                        {
                          oldLink: thumbLink,
                          newLink: newThumbLink,
                        },
                      ],
                    }),
                  },
                };
              } else {
                payload = {
                  action: "update",
                  updatePayload: {
                    newLink: newValue,
                  },
                };
              }
              handleUpdateMktPost({
                briefId: data?.briefId,
                uid,
                data: payload,
                newValue,
                newThumbLink,
              });
            }}
          >
            <IconCheckbox
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  );

  return (
    <Box>
      <ScrollArea>
        <Table miw={500} verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={headCellStyles}>Type</Table.Th>
              <Table.Th style={headCellStyles}>Ảnh cũ</Table.Th>
              <Table.Th style={headCellStyles}>Link mới</Table.Th>
              <Table.Th style={headCellStyles}>Confirm</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default ModalAdLinks;
