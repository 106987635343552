import useTable from "./useTable";
import classes from "./index.module.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Group, Radio, SegmentedControl } from "@mantine/core";
import AppMultiSelect from "../../../components/CustomMultiselect";
import {
  calculateSummaryRowMKTReport,
  CURRENT_MONTH,
  getComparisonColor,
  getMonthArray,
} from "../../../utils";
import InfoTooltip from "../../../components/InfoTooltip";

const DATES = {
  THIRTY_DAYS: {
    LABEL: "30 days",
    VALUE: 31,
  },
  SEVEN_DAYS: {
    LABEL: "7 days",
    VALUE: 7,
  },
  THREE_DAYS: {
    LABEL: "3 days",
    VALUE: 3,
  },
};

const MKTReportTable = ({ query, setQuery, endPoint, elements }) => {
  const {
    data,
    loading,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  } = useTable({
    query,
    setQuery,
    endPoint,
  });
  const summaryData = calculateSummaryRowMKTReport(
    data?.data || [],
    query?.month
  );

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: {
      stripedColor: "#ffffff",
      highlightOnHoverColor: "#ffffff",
    },
    mantineTableHeadProps: {
      className: classes["mantine-Table-thead"],
    },
    columns: [
      {
        accessorKey: "formattedDate",
        header: "Date",
        size: 50,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          className: classes["body-cells"],
          style: {
            backgroundColor: "#f2f3f5",
            fontWeight: "bold",
          },
        },
      },
      {
        accessorKey: "input",
        header: "Input",
        columns: [
          {
            accessorKey: "totalFutureHero",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <InfoTooltip label="Số SKU thỏa điều kiện FH hôm đó">
                    <span>Card vào</span>
                    <span>(Future Hero)</span>
                  </InfoTooltip>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalFutureHero",
                row?.original?.totalFutureHero,
                elements,
                row?.original
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalFutureHero?.toLocaleString() || 0}
                </span>
              );
            },
          },
        ],
      },
      {
        accessorKey: "performance",
        header: "Performance",
        columns: [
          {
            accessorKey: "totalDoneScale",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["important-cells"],
              style: {
                backgroundColor: "#fefff0",
              },
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Done Scale</span>
                </Flex>
              );
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "totalDoneScale",
            //     row?.original?.totalDoneScale,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.totalDoneScale?.toLocaleString() || 0}
            //     </span>
            //   );
            // },
          },
          {
            accessorKey: "averageTimeDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },
            Header: () => {
              return <span>Time TB Done</span>;
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "averageTimeDone",
            //     row?.original?.averageTimeDone,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.averageTimeDone?.toLocaleString() || 0}
            //     </span>
            //   );
            // },
          },
          {
            accessorKey: "totalRemainingScale",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },
            Header: () => {
              return <span>Tồn Scale</span>;
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "totalRemainingScale",
            //     row?.original?.totalRemainingScale,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.totalRemainingScale?.toLocaleString() || 0}
            //     </span>
            //   );
            // },
          },
        ],
      },
      {
        accessorKey: "output",
        header: "Output",
        columns: [
          {
            accessorKey: "totalHero",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["important-cells"],
              style: {
                backgroundColor: "#fefff0",
              },
            },
            Header: () => {
              return (
                <Flex direction="column" align={"center"}>
                  <InfoTooltip>
                    <span>Hero</span>
                  </InfoTooltip>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalHero",
                row?.original?.totalHero,
                elements,
                row?.original
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalHero?.toLocaleString() || 0}
                </span>
              );
            },
          },
          {
            accessorKey: "totalHeroRate",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },
            Header: () => {
              return <span>Hero Rate</span>;
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "totalHeroRate",
            //     row?.original?.totalHeroRate,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.totalHeroRate || 0}
            //     </span>
            //   );
            // },
          },
          {
            accessorKey: "totalAdsRevenue",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },
            Header: () => {
              return <span>Ads/Rev</span>;
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "totalBudgets",
            //     row?.original?.totalBudgets,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.totalBudgets?.toLocaleString() || 0}
            //     </span>
            //   );
            // },
          },
          {
            accessorKey: "spend",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: ({ row }) => {
              const isSummaryRow = row?.original?.isSummaryRow;
              return {
                ...(!isSummaryRow
                  ? { className: classes["body-cells"] }
                  : {
                      className: classes["default-cells"],
                      style: {
                        backgroundColor: "#f2f3f5",
                      },
                    }),
              };
            },

            Header: () => {
              return <span>$ / Time</span>;
            },
            // Cell: ({ row }) => {
            //   const color = getComparisonColor(
            //     "totalBudgets",
            //     row?.original?.totalBudgets,
            //     elements,
            //     row?.original
            //   );
            //   return (
            //     <span
            //       style={{
            //         color: color,
            //       }}
            //     >
            //       {row?.original?.totalBudgets?.toLocaleString() || 0}
            //     </span>
            //   );
            // },
          },
        ],
      },
    ],
    data: [...(data?.data || []), summaryData],
    editDisplayMode: "cell",
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      loading,
    },
    getRowId: (row) => row.id || row.formattedDate,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            marginLeft: "10px",
          }}
        >
          <Group>
            <Radio.Group value={query?.days} onChange={handleChangeDateRange}>
              <Group>
                <Radio
                  value={DATES.THIRTY_DAYS.VALUE}
                  label={DATES.THIRTY_DAYS.LABEL}
                />
                <Radio
                  value={DATES.SEVEN_DAYS.VALUE}
                  label={DATES.SEVEN_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />

                <Radio
                  value={DATES.THREE_DAYS.VALUE}
                  label={DATES.THREE_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />
              </Group>
            </Radio.Group>
            <AppMultiSelect
              placeholder={"Select team"}
              defaultValue={query?.teams}
              options={[
                { label: "BD1", value: "BD1" },
                {
                  label: "BD2",
                  value: "BD2",
                },
                { label: "BD3", value: "BD3" },
                { label: "AMZ", value: "AMZ" },
              ]}
              onChange={(value) => {
                handleChangeTeams(value);
              }}
              onClear={() => {
                onClearTeams();
              }}
            />
          </Group>
          <Group>
            {/* <SegmentedControl
              value={query?.month?.toString()}
              onChange={handleChangeMonth}
              data={map(MKT_LAUNCHING_TYPES, (x) => {
                return {
                  value: `${x}`,
                  label: x,
                };
              })}
            /> */}
            <SegmentedControl
              value={query?.month?.toString()}
              onChange={handleChangeMonth}
              data={getMonthArray().map((x) => {
                return {
                  value: `${x}`,
                  label: `T${x}`,
                };
              })}
            />
          </Group>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkFileIn" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
        "--mrt-row-hover-background-color": "#ffffff",
      },
    }),
    enableStickyHeader: true,
    enableStickyFooter: true,
  });
  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default MKTReportTable;
