import { Autocomplete, Loader } from "@mantine/core";
import { isEmpty, map, uniq, uniqBy } from "lodash";
import Card from "../Card";
import Icon from "../Icon";
import useFetchProducts from "./useFetchProducts";
import { useEffect } from "react";

const SearchSKU = ({ setSKUs }) => {
  const {
    data: products = [],
    searchSKU,
    handleSubmitSKU,
    loading,
    SKU,
    handleChangeSKU,
  } = useFetchProducts();

  useEffect(() => {
    if (!isEmpty(SKU)) {
      setSKUs((prev) => uniqBy([SKU], "sku"));
    }
  }, [SKU]);
  return (
    <Card
      title="Tìm SKU"
      classTitle="title-green"
      head={
        <Autocomplete
          placeholder="Search SKU"
          data={uniq(map(products, "sku"))}
          withScrollArea={true}
          defaultDropdownOpened={false}
          maxDropdownHeight={100}
          leftSection={
            <span
              onClick={handleSubmitSKU}
              style={{
                cursor: "pointer",
              }}
            >
              {loading ? (
                <Loader color="blue" size="xs" />
              ) : (
                <Icon name="search" size={18} />
              )}
            </span>
          }
          value={searchSKU}
          onChange={(value) => {
            handleChangeSKU(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmitSKU();
            }
          }}
        />
      }
    ></Card>
  );
};

export default SearchSKU;
