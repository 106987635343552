import { Select } from "@mantine/core";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../utils";

const SelectValue = ({
  defaultValue,
  handleChange,
  uid,
  isDisabled = false,
}) => {
  return (
    <Select
      data={["Small", "Medium", "Big", "Super Big"]}
      value={CONVERT_NUMBER_TO_STATUS[defaultValue?.rnd]}
      onChange={(value) => {
        handleChange(uid, {
          ...defaultValue,
          rnd: CONVERT_STATUS_TO_NUMBER[value],
        });
      }}
    />
  );
};

export default SelectValue;
