import { filter, isEmpty, keys, omit } from "lodash";
import axios from "axios";
import { AMZ_URL } from "../constant";
import { showNotification } from "../utils/index";

export const amzServices = {
  fetchSaleMetrics: async ({ query, page, limit, sorting }) => {
    try {
      let url = `${AMZ_URL}/sales-metrics?page=${page}&pageSize=${limit}`;
      const queryKeys = keys(query);
      const transformedQuery = filter(queryKeys, (key) => query[key]);
      const emptyKeys = filter(queryKeys, (key) => !query[key]);
      let queryParams = {};
      let sortingParams = {};
      if (!isEmpty(transformedQuery)) {
        queryParams = {
          ...omit(query, emptyKeys),
        };
      }
      if (!isEmpty(sorting)) {
        sortingParams = {
          sortBy: sorting[0].id,
          sortDir: sorting[0].desc ? "desc" : "asc",
        };
      }
      const response = await axios.get(url, {
        params: {
          ...queryParams,
          ...sortingParams,
        },
      });
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at fetchSaleMetrics:", error);
      return false;
    }
  },
  fetchSurvivalModeSaleMetrics: async ({ query, page, limit, sorting }) => {
    try {
      let url = `${AMZ_URL}/sales-metrics/survival-mode?page=${page}&pageSize=${limit}`;
      const queryKeys = keys(query);
      const transformedQuery = filter(queryKeys, (key) => query[key]);
      const emptyKeys = filter(queryKeys, (key) => !query[key]);
      let queryParams = {};
      let sortingParams = {};
      if (!isEmpty(transformedQuery)) {
        queryParams = {
          ...omit(query, emptyKeys),
        };
      }
      if (!isEmpty(sorting)) {
        sortingParams = {
          sortBy: sorting[0].id,
          sortDir: sorting[0].desc ? "desc" : "asc",
        };
      }
      const response = await axios.get(url, {
        params: {
          ...queryParams,
          ...sortingParams,
        },
      });
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at fetchSurvivalModeSaleMetrics:", error);
      return false;
    }
  },
  handleDeleteAMZDashboard: async (id) => {
    try {
      const url = `${AMZ_URL}/optimize-reports/${id}`;
      const response = await axios.delete(url);
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at handleDeleteAMZDashboard:", error);
      return false;
    }
  },
  handleUpdateAMZDashboard: async (sku, data) => {
    try {
      const url = `${AMZ_URL}/asins/${sku}`;
      const response = await axios.put(url, data);
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at handleUpdateAMZDashboard:", error);
      return false;
    }
  },
  handleOptimizedReportAMZDashboard: async (sku, data) => {
    try {
      const url = `${AMZ_URL}/optimize-reports/${sku}`;
      const response = await axios.put(url, data);
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at handleUpdateAMZDashboard:", error);
      return false;
    }
  },
  fetchAllOptimizedReports: async ({ query, page, limit, sorting }) => {
    try {
      let url = `${AMZ_URL}/optimize-reports?page=${page}&pageSize=${limit}`;
      const queryKeys = keys(query);
      const transformedQuery = filter(queryKeys, (key) => query[key]);
      const emptyKeys = filter(queryKeys, (key) => !query[key]);
      let queryParams = {};
      let sortingParams = {};
      if (!isEmpty(transformedQuery)) {
        queryParams = {
          ...omit(query, emptyKeys),
        };
      }
      if (!isEmpty(sorting)) {
        sortingParams = {
          sortBy: sorting[0].id,
          sortDir: sorting[0].desc ? "desc" : "asc",
        };
      }
      const response = await axios.get(url, {
        params: {
          ...queryParams,
          ...sortingParams,
        },
      });
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at fetchAllOptimizedReports:", error);
      return false;
    }
  },
  fetchSKUs: async ({ sku, page, limit }) => {
    try {
      let url = `${AMZ_URL}/asins/search?page=${page}&pageSize=${limit}`;
      const response = await axios.get(url, {
        params: {
          sku,
        },
      });
      const { data: result } = response;
      if (result?.success === "false" || result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at fetchSKUs:", error);
      return false;
    }
  },
  createMarkOptimizedRequest: async (payload) => {
    try {
      const { data: result } = await axios.post(
        `${AMZ_URL}/optimize-reports/batch`,
        payload
      );
      if (result?.success === false) {
        showNotification(
          "Thất bại",
          result?.message || "Tạo mới thất bại",
          "red"
        );
        return false;
      }
      showNotification("Thành công", "Request thành công", "green");
      return result;
    } catch (error) {
      const code = error?.response?.data?.code;
      if (code === 403) {
        showNotification(
          "Thất bại",
          "Bạn không có quyền thực hiện hành động này",
          "red"
        );
      } else {
        console.log("Error at createMarkOptimizedRequest:", error);
        showNotification(
          "Thất bại",
          error?.response?.data?.message || "Tạo mới thất bại",
          "red"
        );
      }
      return false;
    }
  },
};
