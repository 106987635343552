import { useEffect, useState } from "react";
import apiClient from "../../../services/axiosClient";
import { showNotification } from "../../../utils/index";
import { dashboardServices } from "../../../services";
import { find } from "lodash";
import { BRIEF_TYPE_SAMPLE_VIDEO, BRIEF_TYPES } from "../../../constant";

const useCreateRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const [times, setTimes] = useState([]);

  const fetchTimes = async () => {
    try {
      const response = await dashboardServices.fetchDashboardsSetting({
        page: -1,
        limit: -1,
      });
      setTimes(response?.data);
    } catch (error) {
      showNotification("Thất bại", "Đã có lỗi xảy ra", "red");
      setError(error);
    }
  };

  const createRequest = async (data) => {
    setLoading(true);
    setError(null);

    const refactoredPayloads = data.payloads.map((item) => ({
      ...item,
      videoTimeId: find(times, {
        scaleType: BRIEF_TYPE_SAMPLE_VIDEO,
        team: "video",
        size: 2, // default size
      })?.uid,
      designerTimeId: find(times, {
        scaleType: BRIEF_TYPES[0],
        team: "designer",
        size: 2, // default size
      })?.uid,
      epmTimeId: find(times, {
        scaleType: BRIEF_TYPES[0],
        team: "epm",
        size: 2, // default size
      })?.uid,
    }));

    try {
      const response = await apiClient.post("briefs/create-batch", {
        ...data,
        payloads: refactoredPayloads,
      });
      if (response?.data?.success === true) {
        setResponse(response);
        showNotification("Thành công", "Request thành công", "green");
      } else {
        setError(response?.data?.message);
        showNotification("Thất bại", "Đã có lỗi xảy ra", "red");
      }
    } catch (error) {
      const code = error?.response?.data?.code;
      if (code === 403) {
        showNotification(
          "Thất bại",
          "Bạn không có quyền thực hiện hành động này",
          "red"
        );
      } else {
        console.log("Error at fetchQuotes:", error);
        showNotification("Thất bại", "Đã có lỗi xảy ra", "red");
      }
      setError(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchTimes();
  }, []);

  return {
    loading,
    error,
    createRequest,
    response,
  };
};

export default useCreateRequest;
