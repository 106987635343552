import CommonReport from "../CommonReport";

const DesignerReport = () => {
  return (
    <CommonReport
      title="Designer Report"
      view="design"
      settingName="benchmark-design"
    />
  );
};

export default DesignerReport;
