import cn from "classnames";
import { useEffect, useState } from "react";
import Card from "../../components/Card";
import styles from "./CommonReport.module.sass";
import Table from "./Table";
import { CURRENT_MONTH } from "../../utils";
import { settingServices } from "../../services/settings";
import { keys, map } from "lodash";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, SegmentedControl } from "@mantine/core";
import MasterResultReportTable from "./ResultTable";
import Benchmark from "./Benchmark";
import MasterBalanceReportTable from "./BalanceTable";
import MasterResultReportTableV2 from "./ResultTableV2";

const VIEWS = {
  RESULT: "RESULT",
  PERFORMANCE: "PERFORMANCE",
  BALANCE: "Tồn - Days To Done",
};

const getAttributeView = (view) => {
  switch (view) {
    case VIEWS.RESULT:
      return "result";
    case VIEWS.PERFORMANCE:
      return "performance";
    case VIEWS.BALANCE:
      return "balance";
    default:
      return "result";
  }
};

const MasterReport = () => {
  const [query, setQuery] = useState({
    days: 31,
    teams: [
      { label: "BD1", value: "BD1" },
      {
        label: "BD2",
        value: "BD2",
      },
      { label: "BD3", value: "BD3" },
      // { label: "AMZ", value: "AMZ" },
    ],
    month: CURRENT_MONTH,
  });
  const [view, setView] = useState(VIEWS.RESULT);
  const identifier = "benchmark-master";
  const [settings, setSettings] = useState({});
  const [elements, setElements] = useState([]);
  const fetchDefaultSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier,
    });
    const attributeView = getAttributeView(view);
    if (settingsResponse) {
      setSettings(settingsResponse);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute[attributeView]),
        (key) => {
          return {
            metric: key,
            label:
              settingsResponse?.data?.attribute?.[attributeView]?.[key].label,
            goodOperator:
              settingsResponse?.data?.attribute?.[attributeView]?.[key]?.good
                ?.operator,
            goodValue:
              settingsResponse?.data?.attribute?.[attributeView]?.[key]?.good
                ?.value,
            warningOperator:
              settingsResponse?.data?.attribute?.[attributeView]?.[key]?.warning
                ?.operator,
            warningValue:
              settingsResponse?.data?.attribute?.[attributeView]?.[key]?.warning
                ?.value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  useState(() => {
    fetchDefaultSetting();
  }, []);

  useEffect(() => {
    let refactoredElements = [];
    if (view === VIEWS.RESULT) {
      refactoredElements = map(
        keys(settings?.data?.attribute?.result),
        (key) => {
          return {
            metric: key,
            label: settings?.data?.attribute?.result?.[key].label,
            goodOperator:
              settings?.data?.attribute?.result?.[key]?.good?.operator,
            goodValue: settings?.data?.attribute?.result?.[key]?.good?.value,
            warningOperator:
              settings?.data?.attribute?.result?.[key]?.warning?.operator,
            warningValue:
              settings?.data?.attribute?.result?.[key]?.warning?.value,
          };
        }
      );
    } else if (view === VIEWS.PERFORMANCE) {
      refactoredElements = map(
        keys(settings?.data?.attribute?.performance),
        (key) => {
          return {
            metric: key,
            label: settings?.data?.attribute?.performance?.[key]?.label,
            goodOperator:
              settings?.data?.attribute?.performance?.[key]?.good?.operator,
            goodValue:
              settings?.data?.attribute?.performance?.[key]?.good?.value,
            warningOperator:
              settings?.data?.attribute?.performance?.[key]?.warning?.operator,
            warningValue:
              settings?.data?.attribute?.performance?.[key]?.warning?.value,
          };
        }
      );
    } else if (view === VIEWS.BALANCE) {
      refactoredElements = map(
        keys(settings?.data?.attribute?.balance),
        (key) => {
          return {
            metric: key,
            label: settings?.data?.attribute?.balance?.[key]?.label,
            goodOperator:
              settings?.data?.attribute?.balance?.[key]?.good?.operator,
            goodValue: settings?.data?.attribute?.balance?.[key]?.good?.value,
            warningOperator:
              settings?.data?.attribute?.balance?.[key]?.warning?.operator,
            warningValue:
              settings?.data?.attribute?.balance?.[key]?.warning?.value,
          };
        }
      );
    }
    setElements(refactoredElements);
  }, [view]);
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <div>
      <Card
        className={styles.card}
        title={"Master Report"}
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head)}
        head={<Button onClick={open}>Set Benchmark</Button>}
      >
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <SegmentedControl
            value={view}
            onChange={setView}
            data={[
              { value: VIEWS.RESULT, label: VIEWS.RESULT },
              { value: VIEWS.PERFORMANCE, label: VIEWS.PERFORMANCE },
              { value: VIEWS.BALANCE, label: VIEWS.BALANCE },
            ]}
          />
        </div>
        {view === VIEWS.RESULT && (
          <MasterResultReportTableV2
            query={query}
            setQuery={setQuery}
            endPoint="/briefs/report-master"
            elements={elements}
          />
        )}
        {view === VIEWS.PERFORMANCE && (
          <Table
            query={query}
            setQuery={setQuery}
            endPoint="/briefs/report-master"
            elements={elements}
          />
        )}
        {view === VIEWS.BALANCE && (
          <MasterBalanceReportTable
            query={query}
            setQuery={setQuery}
            endPoint="/briefs/report-master"
            elements={elements}
          />
        )}
      </Card>
      <Modal
        opened={opened}
        onClose={() => {
          fetchDefaultSetting();
          close();
        }}
        transitionProps={{ transition: "fade", duration: 200 }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius="md"
        // size="lg"
        title="Set Benchmark"
        styles={{
          title: {
            fontSize: 18,
            fontWeight: 700,
          },
        }}
        size="1000px"
      >
        <Benchmark
          settings={settings}
          elements={elements}
          setElements={setElements}
          view={identifier}
          attributeView={getAttributeView(view)}
        />
      </Modal>
    </div>
  );
};

export default MasterReport;
