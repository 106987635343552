import { useState } from "react";
import { rndServices } from "../../services";
import usePagination from "../../hooks/usePagination";

const useFindSKU = () => {
  const { pagination, handleChangePage, setTotalPages } = usePagination();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getData = async (querySKU) => {
    try {
      setLoading(true);
      const data = await rndServices.searchProducts(querySKU);
      setData(data);
    } catch (error) {
      if (error.message) {
        return setError(error.message);
      }
      setError("Unhandle error");
    } finally {
      setLoading(false);
    }
  };

  return {
    pagination,
    data,
    loading,
    error,
    handleChangePage,
    refetch: getData,
  };
};

export default useFindSKU;
