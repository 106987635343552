import { Button, Grid, Group, Modal } from "@mantine/core";
import Editor from "../../../components/Editor";
import QuillEditor from "../../../components/QuillEditor";
import { getEditorStateAsString } from "../../../utils";
import styles from "./NoteForVideo.module.sass";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const ModalEditNoteForVideoFromSample = ({
  opened,
  close,
  selectedBrief,
  handleUpdateNote,
}) => {
  const [loading, setLoading] = useState(false);
  const [videoNote, setVideoNote] = useState(
    selectedBrief?.note?.noteFromSampleForVideo || ""
  );
  useEffect(() => {
    if (!isEmpty(selectedBrief)) {
      setVideoNote(selectedBrief?.note?.noteFromSampleForVideo || "");
    }
  }, [selectedBrief]);
  return (
    <Modal
      opened={opened}
      onClose={close}
      transitionProps={{ transition: "fade", duration: 200 }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius="md"
      size="lg"
      title="Note From Sample For Video"
      styles={{
        title: {
          fontSize: 18,
          fontWeight: 700,
        },
      }}
    >
      <Grid>
        <Grid.Col span={12}>
          {/* <Editor
            state={videoNote}
            onChange={setVideoNote}
            editorClassName={styles.editorClassName}
            loading={loading}
          /> */}
          <QuillEditor
            state={videoNote}
            onChange={setVideoNote}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Group
            style={{
              justifyContent: "center",
            }}
          >
            <Button
              loading={loading}
              onClick={() => {
                setLoading(true);
                handleUpdateNote(selectedBrief.uid, {
                  note: {
                    ...selectedBrief.note,
                    noteFromSampleForVideo: videoNote,
                  },
                });
                setLoading(false);
                close();
              }}
            >
              Confirm
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default ModalEditNoteForVideoFromSample;
