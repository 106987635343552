import { Center, Divider, Grid, Text } from "@mantine/core";
import QuillEditor from "../../../components/QuillEditor";

const NoteRequest = ({
  sampleValue,
  onChangeSampleValue,
  onChangeVideoValue,
  index,
  videoValue,
  setVideoValue,
}) => {
  return (
    <Grid>
      <Grid.Col>
        <Center>
          <Text>Note - V{index || 1}</Text>
        </Center>
      </Grid.Col>
      <Grid.Col span={6}>
        <QuillEditor
          label="Sample"
          state={sampleValue}
          onChange={(value) => {
            onChangeSampleValue(value);
          }}
          isExpand={false}
          minHeight="300px"
          maxHeight="300px"
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <QuillEditor
          label="Video"
          state={videoValue}
          onChange={(value) => {
            onChangeVideoValue(value);
          }}
          isExpand={false}
          minHeight="300px"
          maxHeight="300px"
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Divider my={20} />
      </Grid.Col>
    </Grid>
  );
};

export default NoteRequest;
