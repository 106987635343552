import { Button, Grid, Group, TextInput } from "@mantine/core";
import Card from "../../components/Card";

import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { rndServices } from "../../services/rnd";

const CreateExternalSku = () => {
  const { isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const handleAddExternalSKU = async () => {
    setLoading(true);
    await rndServices.createExternalSku({
      sku: value,
      store: "thepawfecthouse",
    });
    setLoading(false);
  };
  return isAuthenticated ? (
    <Card title="External SKU" classTitle="title-green">
      <Grid>
        <Grid.Col span={6}>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              width: "100%",
            }}
          >
            <TextInput
              styles={{
                label: {
                  marginBottom: 5,
                  fontWeight: "bold",
                },
              }}
              placeholder="Enter your SKU"
              label="Enter your SKU"
              mt="md"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Button
              loading={loading}
              onClick={handleAddExternalSKU}
              disabled={!value}
            >
              Add
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  ) : (
    <div>Unauthorized</div>
  );
};

export default CreateExternalSku;
