import {
  Button,
  Flex,
  Group,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import moment from "moment-timezone";
import { DateRangePicker } from "rsuite";
import { IconFilterOff } from "@tabler/icons-react";
import { keys, map, toNumber } from "lodash";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
  VALUES,
} from "../../../utils";
import {
  AMZ_DASHBOARD_STATUS,
  CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER,
  FULFILLMENT_CHANNELS,
  MEMBER_MKT_PIC,
} from "../../../constant";
import { useState } from "react";

export const generateSortingAndQueryBaseOnActiveTab = ({
  activeTab,
  oneMonthAgo,
  endDate,
  TARGET_DATES,
  TABS_VIEW,
  currentYear,
  currentWeek,
}) => {
  switch (activeTab) {
    case TABS_VIEW.SURVIVAL:
      return {
        sorting: [{ id: "value", desc: true }],
        query: {
          groupByKey: "date",
          stores: "QZL",
          storeValues: ["QZL"],
          createdDateValue: [new Date(oneMonthAgo), new Date(endDate)],
          startDate: oneMonthAgo,
          endDate,
          values: [1, 2, 3, 4],
          textValue: ["Small", "Medium", "Big", "Super Big"],
        },
      };
    case TABS_VIEW.Date:
      return {
        sorting: [
          {
            id: moment(endDate).format("DD MMM YY"),
            desc: true,
          },
        ],
        query: {
          groupByKey: "date",
          stores: "QZL",
          storeValues: ["QZL"],
          fulfillmentChannelValues: ["FBA", "FBM"],
          fulfillmentChannel: "FBA,FBM",
          toggleTest: true,
          targetDate: TARGET_DATES.THREE_DAYS,
          dateRange: 3,
        },
      };
    case TABS_VIEW.Week:
      return {
        sorting: [
          {
            id: `W${currentWeek} ${currentYear}`,
            desc: true,
          },
        ],
        query: {
          groupByKey: "week",
          stores: "QZL",
          storeValues: ["QZL"],
          fulfillmentChannelValues: ["FBA", "FBM"],
          fulfillmentChannel: "FBA,FBM",
          toggleTest: true,
          targetDate: TARGET_DATES.THREE_DAYS,
          dateRange: 3,
        },
      };
    case TABS_VIEW.Month:
      return {
        sorting: [
          {
            id: `${moment(endDate).format("MMM")} ${currentYear}`,
            desc: true,
          },
        ],
        query: {
          groupByKey: "month",
          stores: "QZL",
          storeValues: ["QZL"],
          fulfillmentChannelValues: ["FBA", "FBM"],
          fulfillmentChannel: "FBA,FBM",
          toggleTest: true,
          targetDate: TARGET_DATES.THREE_DAYS,
          dateRange: 3,
        },
      };
    case TABS_VIEW.OPTIMIZE:
      return {
        sorting: [],
        query: {
          stores: "QZL",
          storeValues: ["QZL"],
          primarySortBy: "totalOrders7d",
          primarySortDir: "desc",
          optimizedList: [
            AMZ_DASHBOARD_STATUS.NOT_TOUCH,
            AMZ_DASHBOARD_STATUS.OPTIMIZED,
          ],
          optimized: [
            CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[
              AMZ_DASHBOARD_STATUS.NOT_TOUCH
            ],
            CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[
              AMZ_DASHBOARD_STATUS.OPTIMIZED
            ],
          ],
        },
      };
    default:
      return {
        sorting: [],
        query: {},
      };
  }
};
export const FilterNormalModeHeader = ({
  query,
  setQuery,
  setIsLoadmore,
  setPagination,
  setIsConfirmedQuery,
  loading,
  pagination,
  setListingDays,
  setAdDaysNum,
  listingDays,
  adDaysNum,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 5px",
        gap: "10px",
        flexWrap: "wrap-reverse",
        width: "100%",
      }}
    >
      <Flex
        style={{
          gap: "8px",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#EFF0F1",
          flexWrap: "wrap",
          width: "100%",
          alignItems: "end",
        }}
      >
        <TextInput
          label="SKU"
          value={query?.sku || ""}
          onChange={(event) => {
            const value = event.target.value;
            setQuery({
              ...query,
              sku: value,
            });
          }}
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            },
            input: {
              width: "100px",
            },
            label: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        />

        <Select
          data={keys(VALUES)}
          placeholder="Value"
          label="Value"
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            },
            input: {
              width: "100px",
            },
            label: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          value={CONVERT_NUMBER_TO_STATUS[query.value] || null}
          onChange={(value) => {
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setQuery({
              ...query,
              value: CONVERT_STATUS_TO_NUMBER[value],
            });
          }}
          clearable
          onClear={() => {
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setQuery({
              ...query,
              value: null,
            });
          }}
        />
        <Group>
          <TextInput
            label="List"
            value={listingDays}
            onChange={(event) => {
              const value = event.target.value;
              setListingDays(value);
              if (value) {
                setQuery({
                  ...query,
                  listingDays: toNumber(value),
                });
              }
            }}
            styles={{
              root: {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              },
              input: {
                width: "70px",
              },
              label: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
          />
          <TextInput
            label="Ads"
            value={adDaysNum}
            onChange={(event) => {
              const value = event.target.value;
              setAdDaysNum(value);
              if (value) {
                setQuery({
                  ...query,
                  adDays: toNumber(value),
                });
              }
            }}
            // styles={{
            //   root: {
            //     display: "flex",
            //     alignItems: "center",
            //     gap: "10px",
            //   },
            //   input: {
            //     width: "70px",
            //   },
            //   label: {
            //     fontSize: "12px",
            //     fontWeight: "bold",
            //   },
            // }}
            styles={{
              root: {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              },
              input: {
                width: "70px",
              },
              label: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
          />
        </Group>
        <Button
          loading={loading}
          onClick={() => {
            setIsLoadmore(false);
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setIsConfirmedQuery(true);
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setIsConfirmedQuery(true);
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setListingDays("");
            setAdDaysNum("");
            setQuery({
              stores: null,
              fulfillmentChannel: null,
              fulfillmentChannelValues: [],
              sortValue: null,
              sortBy: null,
              sortDir: null,
              storeValues: [],
              dateValue: null,
              startDate: null,
              endDate: null,
              primarySortBy: null,
              primarySortDir: null,
              salesDateValue: null,
              startCreatedDate: null,
              endCreatedDate: null,
              minOrders: "",
              listingDays: "",
              adDays: "",
            });
          }}
        >
          <IconFilterOff />
        </Button>
      </Flex>
    </div>
  );
};
export const FilterSurvivalModeHeader = ({
  query,
  setQuery,
  setIsLoadmore,
  setPagination,
  setIsConfirmedQuery,
  loading,
  pagination,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 5px",
        gap: "10px",
        flexWrap: "wrap-reverse",
      }}
    >
      <Flex
        style={{
          gap: "8px",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#EFF0F1",
          flexWrap: "wrap",
        }}
      >
        <DateRangePicker
          size="sx"
          placeholder="Created Date"
          style={{
            width: "200px",
          }}
          value={query.createdDateValue}
          onOk={(value) => {
            setQuery({
              ...query,
              createdDateValue: value,
              startDate: moment(value[0]).format("YYYY-MM-DD"),
              endDate: moment(value[1]).format("YYYY-MM-DD"),
            });
          }}
          onOpen={() => {
            console.log("open");
          }}
          onClean={() => {
            setQuery({
              ...query,
              createdDateValue: null,
              startDate: null,
              endDate: null,
            });
          }}
          onShortcutClick={(shortcut) => {
            setQuery({
              ...query,
              createdDateValue: shortcut.value,
              startDate: moment(shortcut.value[0]).format("YYYY-MM-DD"),
              endDate: moment(shortcut.value[1]).format("YYYY-MM-DD"),
            });
          }}
        />
        <Button
          loading={loading}
          onClick={() => {
            setIsLoadmore(false);
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setIsConfirmedQuery(true);
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setIsConfirmedQuery(true);
            setPagination({
              ...pagination,
              currentPage: 1,
            });
            setQuery({
              stores: null,
              fulfillmentChannel: [],
              sortValue: null,
              sortBy: null,
              sortDir: null,
              storeValues: [],
              dateValue: null,
              startDate: null,
              endDate: null,
              primarySortBy: null,
              primarySortDir: null,
              createdDateValue: null,
              salesStartDate: null,
              salesEndDate: null,
              ordersInRange: "",
            });
          }}
        >
          <IconFilterOff />
        </Button>
      </Flex>
    </div>
  );
};

export const FilterOptimizedModeHeader = ({
  query,
  setQuery,
  setIsLoadmore,
  setPagination,
  setIsConfirmedQuery,
  loading,
  pagination,
  setListingDays,
  setAdDaysNum,
}) => {
  const [filter, setFilter] = useState({
    ...query,
  });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 5px",
        gap: "10px",
        flexWrap: "wrap-reverse",
      }}
    >
      <Flex
        style={{
          gap: "8px",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#EFF0F1",
          flexWrap: "wrap",
        }}
      >
        <TextInput
          label="SKU"
          value={filter?.sku || ""}
          onChange={(event) => {
            const value = event.target.value;
            setFilter({
              ...filter,
              sku: value,
            });
          }}
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            },
            input: {
              width: "100px",
            },
            label: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        />
        <Select
          data={keys(VALUES)}
          placeholder="Value"
          label="Value"
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            },
            input: {
              width: "100px",
            },
            label: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          value={CONVERT_NUMBER_TO_STATUS[filter.value] || null}
          onChange={(value) => {
            setFilter({
              ...filter,
              value: CONVERT_STATUS_TO_NUMBER[value],
            });
          }}
          clearable
          onClear={() => {
            setFilter({
              ...filter,
              value: null,
            });
          }}
        />

        <Select
          data={map(MEMBER_MKT_PIC, "value")}
          placeholder="PIC"
          label="PIC"
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            },
            input: {
              width: "100px",
            },
            label: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          value={filter?.pic || null}
          onChange={(value) => {
            setFilter({
              ...filter,
              pic: value,
            });
          }}
          clearable
          onClear={() => {
            setFilter({
              ...filter,
              pic: null,
            });
          }}
        />

        <Group>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              Checked Date
            </label>
            <DateRangePicker
              size="sx"
              placeholder="Checked Date"
              style={{
                width: "200px",
              }}
              value={filter.checkedDateValue}
              onOk={(value) => {
                setFilter({
                  ...filter,
                  checkedDateValue: value,
                  checkedDateStart: moment(value[0]).format("YYYY-MM-DD"),
                  checkedDateEnd: moment(value[1]).format("YYYY-MM-DD"),
                });
              }}
              onClean={() => {
                setFilter({
                  ...filter,
                  checkedDateValue: null,
                  checkedDateStart: null,
                  checkedDateEnd: null,
                });
              }}
              onShortcutClick={(shortcut) => {
                setFilter({
                  ...filter,
                  checkedDateValue: shortcut.value,
                  checkedDateStart: moment(shortcut.value[0]).format(
                    "YYYY-MM-DD"
                  ),
                  checkedDateEnd: moment(shortcut.value[1]).format(
                    "YYYY-MM-DD"
                  ),
                });
              }}
            />
          </Group>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              Channel
            </label>
            <MultiSelect
              data={FULFILLMENT_CHANNELS}
              placeholder="Channel"
              styles={{
                input: {
                  width: "200px",
                },
                inputField: {
                  display: "none",
                },
              }}
              value={filter?.fulfillmentChannel}
              onChange={(value) => {
                setFilter({ ...filter, fulfillmentChannel: value });
              }}
            />
          </Group>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              Status
            </label>
            <MultiSelect
              data={keys(CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER)}
              placeholder="Status"
              styles={{
                input: {
                  width: "400px",
                },
                inputField: {
                  display: "none",
                },
              }}
              value={filter?.optimizedList}
              onChange={(value) => {
                setFilter({ ...filter, optimizedList: value });
              }}
            />
            {/* <AppMultiSelect
            options={map(
              keys(CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER),
              (key) => ({
                label: key,
                value: CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[key],
              })
            )}
            placeholder="Status"
            styles={{
              input: {
                width: "150px",
              },
            }}
            onClear={() => {
              setFilter({
                ...filter,
                optimized: null,
              });
            }}
            defaultValue={filter?.optimized}
            onChange={(value) => {
              setFilter({ ...filter, optimized: value });
            }}
          /> */}
          </Group>

          <Group mt="30px">
            <Button
              loading={loading}
              onClick={() => {
                setIsLoadmore(false);
                setPagination({
                  ...pagination,
                  currentPage: 1,
                });
                setIsConfirmedQuery(true);
                setQuery({
                  ...filter,
                  ...(filter?.optimizedList && {
                    optimized: map(
                      filter.optimizedList,
                      (key) => CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[key]
                    ),
                  }),
                });
              }}
            >
              Confirm
            </Button>
            <Button
              onClick={() => {
                setIsConfirmedQuery(true);
                setPagination({
                  ...pagination,
                  currentPage: 1,
                });
                setFilter({
                  checkedDateValue: null,
                  optimizedList: [
                    AMZ_DASHBOARD_STATUS.NOT_TOUCH,
                    AMZ_DASHBOARD_STATUS.OPTIMIZED,
                  ],
                  fulfillmentChannel: FULFILLMENT_CHANNELS,
                });
                setListingDays("");
                setAdDaysNum("");
                setQuery({
                  stores: null,
                  sortValue: null,
                  sortBy: null,
                  sortDir: null,
                  storeValues: [],
                  dateValue: null,
                  startDate: null,
                  endDate: null,
                  primarySortBy: "totalOrders7d",
                  primarySortDir: "desc",
                  createdDateValue: null,
                  salesStartDate: null,
                  salesEndDate: null,
                  ordersInRange: "",
                  checkedDateValue: null,
                  checkedDateStart: null,
                  checkedDateEnd: null,
                  fulfillmentChannel: FULFILLMENT_CHANNELS,
                  optimized: [
                    CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[
                      AMZ_DASHBOARD_STATUS.NOT_TOUCH
                    ],
                    CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[
                      AMZ_DASHBOARD_STATUS.OPTIMIZED
                    ],
                  ],
                });
              }}
            >
              <IconFilterOff />
            </Button>
          </Group>
        </Group>
      </Flex>
    </div>
  );
};
