import { Group, Select, Table, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { isEmpty, keys, map, toNumber } from "lodash";
import { settingServices } from "../../services/settings";

const TableMetricCondition = ({ settings, elements, setElements, view }) => {
  const [payload, setPayload] = useState({});
  const updateDefaultSampleSetting = async ({ uid, payload }) => {
    await settingServices.updateSetting({
      uid,
      data: payload,
      view: view || "video",
    });
  };
  useEffect(() => {
    if (!isEmpty(settings)) {
      setPayload(settings?.data);
      const refactoredElements = map(keys(settings?.data?.attribute), (key) => {
        return {
          metric: key,
          label: settings?.data?.attribute?.[key].label,
          goodOperator: settings?.data?.attribute?.[key]?.good?.operator,
          goodValue: settings?.data?.attribute?.[key]?.good?.value,
          warningOperator: settings?.data?.attribute?.[key]?.warning?.operator,
          warningValue: settings?.data?.attribute?.[key]?.warning?.value,
        };
      });
      setElements(refactoredElements);
    }
  }, [settings]);

  const rows = map(elements, (element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.label}</Table.Td>
      <Table.Td>
        <Group>
          <Select
            styles={{
              root: {
                width: "100px",
              },
            }}
            data={[
              { value: "greaterOrEqual", label: ">=" },
              { value: "greater", label: ">" },
              { value: "equal", label: "=" },
              { value: "lessOrEqual", label: "<=" },
              { value: "less", label: "<" },
              // { value: "notEqual", label: "≠" },
            ]}
            value={element.goodOperator}
            onChange={(value) => {
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    goodOperator: value,
                  };
                }
                return el;
              });
              const newPayload = {
                ...payload,
                attribute: {
                  ...payload?.attribute,
                  [key]: {
                    ...payload?.attribute?.[key],
                    good: {
                      ...payload?.attribute?.[key]?.good,
                      operator: value,
                    },
                  },
                },
              };
              setElements(newElements);
              setPayload(newPayload);
              const newAttribute = {
                ...payload?.attribute,
                [key]: {
                  ...payload?.attribute?.[key],
                  good: {
                    ...payload?.attribute?.[key]?.good,
                    operator: value,
                  },
                },
              };
              updateDefaultSampleSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            value={element?.goodValue}
            onChange={(event) => {
              const value = event.target.value;
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    goodValue: value,
                  };
                }
                return el;
              });
              const newPayload = {
                ...payload,
                attribute: {
                  ...payload?.attribute,
                  [key]: {
                    ...payload?.attribute?.[key],
                    good: {
                      ...payload?.attribute?.[key]?.good,
                      value: value ? toNumber(value) : "",
                    },
                  },
                },
              };
              setPayload(newPayload);
              setElements(newElements);
              const newAttribute = {
                ...payload?.attribute,
                [key]: {
                  ...payload?.attribute?.[key],
                  good: {
                    ...payload?.attribute?.[key]?.good,
                    value: value ? toNumber(value) : "",
                  },
                },
              };
              updateDefaultSampleSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
        </Group>
      </Table.Td>
      <Table.Td>
        <Group>
          <Select
            styles={{
              root: {
                width: "100px",
              },
            }}
            data={[
              { value: "greaterOrEqual", label: ">=" },
              { value: "greater", label: ">" },
              { value: "equal", label: "=" },
              { value: "lessOrEqual", label: "<=" },
              { value: "less", label: "<" },
              // { value: "notEqual", label: "≠" },
            ]}
            value={element.warningOperator}
            onChange={(value) => {
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    warningOperator: value,
                  };
                }
                return el;
              });
              const newPayload = {
                ...payload,
                attribute: {
                  ...payload?.attribute,
                  [key]: {
                    ...payload?.attribute?.[key],
                    warning: {
                      ...payload?.attribute?.[key]?.warning,
                      operator: value,
                    },
                  },
                },
              };
              setPayload(newPayload);
              setElements(newElements);
              const newAttribute = {
                ...payload?.attribute,
                [key]: {
                  ...payload?.attribute?.[key],
                  warning: {
                    ...payload?.attribute?.[key]?.warning,
                    operator: value,
                  },
                },
              };
              updateDefaultSampleSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            value={element?.warningValue}
            onChange={(event) => {
              const value = event.target.value;
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    warningValue: value,
                  };
                }
                return el;
              });
              const newPayload = {
                ...payload,
                attribute: {
                  ...payload?.attribute,
                  [key]: {
                    ...payload?.attribute?.[key],
                    warning: {
                      ...payload?.attribute?.[key]?.warning,
                      value: value ? toNumber(value) : "",
                    },
                  },
                },
              };
              setPayload(newPayload);
              setElements(newElements);
              const newAttribute = {
                ...payload?.attribute,
                [key]: {
                  ...payload?.attribute?.[key],
                  warning: {
                    ...payload?.attribute?.[key]?.warning,
                    value: value ? toNumber(value) : "",
                  },
                },
              };
              updateDefaultSampleSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  return (
    <div
    //   style={{
    //     width: "50%",
    //   }}
    >
      <Table
        stickyHeader
        stickyHeaderOffset={60}
        mt="md"
        horizontalSpacing="xl"
        __size="md"
        borderColor="gray"
        striped="even"
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>Chỉ số</Table.Th>
            <Table.Th style={headCellStyles}>Good</Table.Th>
            <Table.Th style={headCellStyles}>Warning</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

export default TableMetricCondition;
