import { Badge } from "@mantine/core";
import { CONVERT_NUMBER_TO_STATUS } from "../../utils";

const UIDSize = ({ size }) => {
  let color = null;
  switch (size) {
    case 1:
      color = "green";
      break;
    case 2:
      color = "yellow";
      break;
    case 3:
      color = "red";
      break;
    case 1.5:
      color = "#006400";
      break;
    default:
      break;
  }
  return color ? (
    <Badge color={color} variant="filled">
      {CONVERT_NUMBER_TO_STATUS[size]}
    </Badge>
  ) : (
    <span>{CONVERT_NUMBER_TO_STATUS[size]}</span>
  );
};

export default UIDSize;
