import { Flex, Grid, Group } from "@mantine/core";

const CardInformation = ({
  totalRows,
  totalVideoCardsAMZ,
  totalVideoCardsBD1,
  totalVideoCardsBD2,
  totalVideoCardsBD3,
  totalVideoCardsUnknown,
  totalSampleVideoDelaySource,
  totalVideoCards,
}) => {
  return (
    <Grid
      style={{
        padding: "10px",
        display: "flex",
        justifyContent: "start",
      }}
    >
      <Grid.Col span={8}>
        <Flex
          style={{
            gap: "30px",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "#EFF0F1",
          }}
          justify="space-between"
          align="center"
        >
          <Group>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Số Card: {totalVideoCards || 0}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              BD1: {totalVideoCardsBD1 || 0}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              BD2:{totalVideoCardsBD2 || 0}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              BD3: {totalVideoCardsBD3 || 0}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              AMZ: {totalVideoCardsAMZ || 0}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Unknown: {totalVideoCardsUnknown || 0}
            </div>
          </Group>
          <Group>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Chậm source: {totalSampleVideoDelaySource || 0}
            </div>
          </Group>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default CardInformation;
