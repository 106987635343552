import { useCallback, useEffect, useState } from "react";
import apiClient from "../../../services/axiosClient";
import { getKeyHasValue } from "../../../utils";

const useGetSampleList = ({
  filters = {},
  defaultFilters = {},
  endPoint = "/briefs/video",
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const refactoredFilters = getKeyHasValue({
        query: filters,
        specialKeys: ["teams"],
      });

      const result = await apiClient.get(endPoint, {
        params: {
          ...refactoredFilters,
        },
      });

      setData(result.data);
    } catch (error) {
      if (error.message) {
        return setError(error.message);
      }
      setError("Unhandle error");
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    data,
    loading,
    error,
    refetch: getData,
  };
};

export default useGetSampleList;
