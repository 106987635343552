import { Badge } from "@mantine/core";

const BadgeLink = ({ link }) => {
  return (
    <a
      style={{
        cursor: "pointer",
      }}
      target="_blank"
      href={link}
      without
      rel="noreferrer"
    >
      {link ? (
        <Badge color="blue" variant="filled">
          {" "}
          <u>Link</u>{" "}
        </Badge>
      ) : null}
    </a>
  );
};

export default BadgeLink;
