import cn from "classnames";
import { useState } from "react";
import Card from "../../components/Card";
import styles from "./CommonReport.module.sass";
import Table from "./Table";
import { CURRENT_MONTH } from "../../utils";
import { useDisclosure } from "@mantine/hooks";
import { settingServices } from "../../services/settings";
import { keys, map } from "lodash";
import { Button, Modal } from "@mantine/core";
import TableMetricCondition from "../../components/TableMetricCondition";

const MKTReport = () => {
  const [query, setQuery] = useState({
    days: 31,
    teams: [
      { label: "BD1", value: "BD1" },
      {
        label: "BD2",
        value: "BD2",
      },
    ],
    month: CURRENT_MONTH,
  });
  const identifier = "benchmark-mkt";
  const [settings, setSettings] = useState({});
  const [elements, setElements] = useState([]);
  const fetchDefaultSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier,
    });
    if (settingsResponse) {
      setSettings(settingsResponse);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute),
        (key) => {
          return {
            metric: key,
            label: settingsResponse?.data?.attribute?.[key].label,
            goodOperator:
              settingsResponse?.data?.attribute?.[key]?.good?.operator,
            goodValue: settingsResponse?.data?.attribute?.[key]?.good?.value,
            warningOperator:
              settingsResponse?.data?.attribute?.[key]?.warning?.operator,
            warningValue:
              settingsResponse?.data?.attribute?.[key]?.warning?.value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  useState(() => {
    fetchDefaultSetting();
  }, []);
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <div>
      <Card
        className={styles.card}
        title={"Launching MKT Report"}
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head)}
        head={<Button onClick={open}>Set Benchmark</Button>}
      >
        <Table
          query={query}
          setQuery={setQuery}
          endPoint="/briefs/report-mkt"
          elements={elements}
        />
      </Card>
      <Modal
        opened={opened}
        onClose={() => {
          fetchDefaultSetting();
          close();
        }}
        transitionProps={{ transition: "fade", duration: 200 }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius="md"
        // size="lg"
        title="Set Benchmark"
        styles={{
          title: {
            fontSize: 18,
            fontWeight: 700,
          },
        }}
        size="1000px"
      >
        <TableMetricCondition
          settings={settings}
          elements={elements}
          setElements={setElements}
          view={identifier}
        />
      </Modal>
    </div>
  );
};

export default MKTReport;
