export const AMZ_URL = "https://amz-dashboard-staging.uidevify.com/api";
export const AMZ_STORES = ["PFH", "QZL", "GGT", "All"];
export const FULFILLMENT_CHANNELS = ["FBM", "FBA"];
export const AMZ_SORTING = {
  ordersAsc: "Best selling (A-Z)",
  ordersDesc: "Best selling (Z-A)",
  saleInRangeAsc: "Sales in range (A-Z)",
  saleInRangeDesc: "Sales in range (Z-A)",
  createdDateAsc: "Created Date (A-Z)",
  createdDateDesc: "Created Date (Z-A)",
};
export const AMZ_DASHBOARD_STATUS = {
  NOT_TOUCH: "Chưa touch",
  OPTIMIZED: "Optimized",
  DONE: "Done",
};
export const CONVERT_NUMBER_TO_AMZ_DASHBOARD_STATUS = {
  0: AMZ_DASHBOARD_STATUS.NOT_TOUCH,
  1: AMZ_DASHBOARD_STATUS.OPTIMIZED,
  2: AMZ_DASHBOARD_STATUS.DONE,
};
export const CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER = {
  [AMZ_DASHBOARD_STATUS.NOT_TOUCH]: 0,
  [AMZ_DASHBOARD_STATUS.OPTIMIZED]: 1,
  [AMZ_DASHBOARD_STATUS.DONE]: 2,
};
export const AMZ_EPM_CHECK_STATUS = {
  CHECKED_MATERIAL: "Đã check - Đủ Material",
  PREVIEW_ONLY: "Preview Only",
  PREVIEW_CLIPART: "Preview + Clipart",
  PREVIEW_CLIPART_ARTIST: "Preview + Clipart + Artist lọc",
};

export const MEMBER_MKT_PIC = [
  {
    value: "Hằng",
    label: "Hằng",
  },
  {
    value: "Duy",
    label: "Duy",
  },
  {
    value: "Yến",
    label: "Yến",
  },
];
