import useTable from "./useTable";
import classes from "./index.module.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Group, Radio, SegmentedControl } from "@mantine/core";
import AppMultiSelect from "../../../components/CustomMultiselect";
import {
  calculateSummaryRowMasterReport,
  CURRENT_MONTH,
  getComparisonColor,
  getMonthArray,
} from "../../../utils";
import { concat } from "lodash";

const DATES = {
  THIRTY_DAYS: {
    LABEL: "30 days",
    VALUE: 31,
  },
  SEVEN_DAYS: {
    LABEL: "7 days",
    VALUE: 7,
  },
  THREE_DAYS: {
    LABEL: "3 days",
    VALUE: 3,
  },
};

const MasterBalanceReportTable = ({ query, setQuery, endPoint, elements }) => {
  const {
    data,
    loading,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  } = useTable({
    query,
    setQuery,
    endPoint,
  });
  const summaryData = calculateSummaryRowMasterReport(data || [], query?.month);

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: {
      stripedColor: "#ffffff",
      highlightOnHoverColor: "#ffffff",
    },
    columns: [
      {
        accessorKey: "formattedDate",
        header: "Date",
        size: 90,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          className: classes["body-cells"],
          style: {
            backgroundColor: "#f2f3f5",
            fontWeight: "bold",
          },
        },
      },
      {
        accessorKey: "balance",
        header: "Tồn - Days to Done",
        mantineTableBodyCellProps: {
          className: classes["body-cells"],
        },
        columns: [
          {
            accessorKey: "designBalance",
            size: 40,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Design</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const colorDesignBalance = getComparisonColor(
                "designBalance",
                row?.original?.design?.balance,
                elements
              );
              const averageTimeDoneColor = getComparisonColor(
                "designDaysToDone",
                row?.original?.design?.daysToDone,
                elements
              );
              return (
                <Flex justify="space-between">
                  <span style={{ color: colorDesignBalance }}>
                    {row?.original?.design?.balance}
                  </span>
                  <span style={{ color: averageTimeDoneColor }}>
                    {row?.original?.design?.daysToDone}{" "}
                    {row?.original?.design?.daysToDone > 1 ? "days" : "day"}
                  </span>
                </Flex>
              );
            },
          },
          {
            accessorKey: "epmBalance",
            size: 40,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Listing</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const colorBalance = getComparisonColor(
                "epmBalance",
                row?.original?.epm?.balance,
                elements
              );
              const averageTimeDoneColor = getComparisonColor(
                "epmDaysToDone",
                row?.original?.epm?.daysToDone,
                elements
              );
              return (
                <Flex justify="space-between">
                  <span style={{ color: colorBalance }}>
                    {row?.original?.epm?.balance}
                  </span>
                  <span style={{ color: averageTimeDoneColor }}>
                    {row?.original?.epm?.daysToDone}{" "}
                    {row?.original?.epm?.daysToDone > 1 ? "days" : "day"}
                  </span>
                </Flex>
              );
            },
          },
          {
            accessorKey: "sampleBalance",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },

            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Sample</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const colorBalance = getComparisonColor(
                "sampleBalance",
                row?.original?.sample?.balance,
                elements
              );

              return (
                <span style={{ color: colorBalance }}>
                  {row?.original?.sample?.balance}
                </span>
              );
            },
          },
          {
            accessorKey: "videoBalance",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Video</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const colorBalance = getComparisonColor(
                "videoBalance",
                row?.original?.video?.balance,
                elements
              );
              const averageTimeDoneColor = getComparisonColor(
                "videoTotalTimeToDoneEdit",
                row?.original?.video?.totalTimeToDoneEdit,
                elements
              );
              return (
                <Flex justify="space-between">
                  <span style={{ color: colorBalance }}>
                    {row?.original?.video?.balance}
                  </span>
                  <span style={{ color: averageTimeDoneColor }}>
                    {row?.original?.video?.totalTimeToDoneEdit}{" "}
                    {row?.original?.video?.totalTimeToDoneEdit > 1
                      ? "days"
                      : "day"}
                  </span>
                </Flex>
              );
            },
          },
        ],
      },
    ],
    data: concat(data, summaryData),
    editDisplayMode: "cell",
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      loading,
    },
    getRowId: (row) => row.id || row.formattedDate,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            marginLeft: "10px",
          }}
        >
          <Group>
            <Radio.Group value={query?.days} onChange={handleChangeDateRange}>
              <Group>
                <Radio
                  value={DATES.THIRTY_DAYS.VALUE}
                  label={DATES.THIRTY_DAYS.LABEL}
                />
                <Radio
                  value={DATES.SEVEN_DAYS.VALUE}
                  label={DATES.SEVEN_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />

                <Radio
                  value={DATES.THREE_DAYS.VALUE}
                  label={DATES.THREE_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />
              </Group>
            </Radio.Group>
            <AppMultiSelect
              placeholder={"Select team"}
              defaultValue={query?.teams}
              options={[
                { label: "BD1", value: "BD1" },
                {
                  label: "BD2",
                  value: "BD2",
                },
                { label: "BD3", value: "BD3" },
                { label: "AMZ", value: "AMZ" },
              ]}
              onChange={(value) => {
                handleChangeTeams(value);
              }}
              onClear={() => {
                onClearTeams();
              }}
            />
          </Group>
          <Group>
            <SegmentedControl
              value={query?.month?.toString()}
              onChange={handleChangeMonth}
              data={getMonthArray().map((x) => {
                return {
                  value: `${x}`,
                  label: `T${x}`,
                };
              })}
            />
          </Group>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkFileIn" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
        "--mrt-row-hover-background-color": "#ffffff",
        borderRight: "1px solid #d1d6e3",
      },
    }),
    mantineTableHeadProps: {
      className: classes["mantine-Table-thead"],
    },
    enableStickyHeader: true,
  });

  return (
    <div
      style={{
        width: "70%",
      }}
    >
      <MantineReactTable table={table} />
    </div>
  );
};

export default MasterBalanceReportTable;
