import { Select, Table, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { settingServices } from "../../../services/settings";
import { keys, map, toNumber } from "lodash";
import { IconPercentage } from "@tabler/icons-react";
import Value from "./Value";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../../utils";

const PODSetting = () => {
  const [elements, setElements] = useState([]);
  const [payload, setPayload] = useState({});
  const fetchDefaultPODSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier: "pod-setting",
    });
    if (settingsResponse) {
      setPayload(settingsResponse?.data);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute?.optimizedMetric),
        (key) => {
          return {
            metric: key,
            operator:
              settingsResponse?.data?.attribute?.optimizedMetric[key].operator,
            value:
              settingsResponse?.data?.attribute?.optimizedMetric[key].value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  const updateDefaultPODSetting = async ({ uid, payload }) => {
    await settingServices.updateSetting({
      uid,
      data: payload,
      view: "pod-setting",
    });
  };
  useEffect(() => {
    fetchDefaultPODSetting();
  }, []);
  const [isOnEdit, setIsOnEdit] = useState(false);
  const rows = map(elements, (element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.metric}</Table.Td>
      <Table.Td>
        <Select
          styles={{
            root: {
              width: "100px",
            },
          }}
          data={[
            { value: "greaterOrEqual", label: ">=" },
            { value: "greater", label: ">" },
            { value: "equal", label: "=" },
            { value: "lessOrEqual", label: "<=" },
            { value: "less", label: "<" },
          ]}
          value={element.operator}
          onChange={(value) => {
            const key = element?.metric;
            const newElements = elements.map((el) => {
              if (el.metric === key) {
                return {
                  ...el,
                  operator: value,
                };
              }
              return el;
            });
            setElements(newElements);
            const newAttribute = {
              ...payload?.attribute,
              optimizedMetric: {
                ...payload?.attribute.optimizedMetric,
                [key]: {
                  ...payload?.attribute.optimizedMetric[key],
                  operator: value,
                },
              },
            };
            updateDefaultPODSetting({
              uid: payload.uid,
              payload: {
                attribute: newAttribute,
              },
            });
          }}
        />
      </Table.Td>
      <Table.Td>
        {element?.metric !== "value" && (
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            rightSection={
              element?.metric === "adsRev" ? <IconPercentage /> : null
            }
            value={element?.value}
            onChange={(event) => {
              const value = event.target.value;
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    value,
                  };
                }
                return el;
              });
              setElements(newElements);
              const newAttribute = {
                ...payload?.attribute,
                optimizedMetric: {
                  ...payload?.attribute.optimizedMetric,
                  [key]: {
                    ...payload?.attribute.optimizedMetric[key],
                    value: value ? toNumber(value) : 0,
                  },
                },
              };
              updateDefaultPODSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
        )}
        {element.metric === "value" &&
          (!isOnEdit ? (
            <Value
              value={element.value}
              onDoubleClick={() => {
                setIsOnEdit(true);
              }}
            />
          ) : (
            <Select
              data={["Small", "Medium", "Big", "Super Big"]}
              styles={{
                root: {
                  width: "150px",
                },
              }}
              value={CONVERT_NUMBER_TO_STATUS[element?.value]}
              onChange={(value) => {
                const key = element?.metric;
                const newElements = elements.map((el) => {
                  if (el.metric === key) {
                    return {
                      ...el,
                      value: CONVERT_STATUS_TO_NUMBER[value],
                    };
                  }
                  return el;
                });
                setElements(newElements);
                const newAttribute = {
                  ...payload?.attribute,
                  optimizedMetric: {
                    ...payload?.attribute.optimizedMetric,
                    [key]: {
                      ...payload?.attribute.optimizedMetric[key],
                      value: CONVERT_STATUS_TO_NUMBER[value],
                    },
                  },
                };
                updateDefaultPODSetting({
                  uid: payload.uid,
                  payload: {
                    attribute: newAttribute,
                  },
                });
              }}
              onDropdownClose={() => {
                setIsOnEdit(false);
              }}
            />
          ))}
      </Table.Td>
    </Table.Tr>
  ));

  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  return (
    <div
      style={{
        width: "50%",
      }}
    >
      <Table
        stickyHeader
        stickyHeaderOffset={60}
        mt="md"
        horizontalSpacing="xl"
        __size="md"
        borderColor="gray"
        striped="even"
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>Chỉ số</Table.Th>
            <Table.Th style={headCellStyles}>Điều kiện</Table.Th>
            <Table.Th style={headCellStyles}>Giá trị</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

export default PODSetting;
