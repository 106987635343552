import { Select } from "@mantine/core";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../utils";

const EditSize = ({ size, onChange }) => {
  return (
    <Select
      data={["Small", "Small+", "Medium", "Big"]}
      value={CONVERT_NUMBER_TO_STATUS[size]}
      onChange={(value) => {
        onChange?.(CONVERT_STATUS_TO_NUMBER[value]);
      }}
    />
  );
};

export default EditSize;
