import { Table, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { settingServices } from "../../../services/settings";
import { filter, find, keys, map, toNumber } from "lodash";

const DefineSku = () => {
  const [elements, setElements] = useState([]);
  const [payload, setPayload] = useState({});
  const fetchDefaultSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier: "sku-result",
    });
    if (settingsResponse) {
      setPayload(settingsResponse?.data);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute),
        (key) => {
          if (key === "weight") {
            return {
              metric: key,
              label: settingsResponse?.data?.attribute?.[key]?.label,
              operator: "equal",
              value: settingsResponse?.data?.attribute?.[key]?.value,
            };
          }
          return {
            metric: key,
            label: settingsResponse?.data?.attribute?.[key]?.oneDay?.label,
            operator:
              settingsResponse?.data?.attribute?.[key]?.oneDay?.operator,
            value: settingsResponse?.data?.attribute?.[key]?.oneDay?.value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  const updateDefaultPODSetting = async ({ uid, payload }) => {
    await settingServices.updateSetting({
      uid,
      data: payload,
      view: "sku-result",
    });
  };
  useEffect(() => {
    fetchDefaultSetting();
  }, []);
  const rows = map(elements, (element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element?.label}</Table.Td>
      <Table.Td>
        {element?.metric !== "weight" && (
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            value={element?.value}
            onChange={(event) => {
              const value = event.target.value;
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    value,
                  };
                }
                return el;
              });
              setElements(newElements);
              const newAttribute =
                key !== "weight"
                  ? {
                      ...payload?.attribute,
                      [key]: {
                        ...payload?.attribute?.[key],
                        oneDay: {
                          ...payload?.attribute?.[key]?.oneDay,
                          value: toNumber(value),
                        },
                      },
                    }
                  : {
                      ...payload?.attribute,
                      [key]: {
                        ...payload?.attribute?.[key],
                        value: toNumber(value),
                      },
                    };
              updateDefaultPODSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
        )}
      </Table.Td>
      <Table.Td>
        {element?.metric !== "weight" && (
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            value={element?.value * find(elements, { metric: "weight" })?.value}
          />
        )}
      </Table.Td>
      <Table.Td>
        {element?.metric === "weight" && (
          <TextInput
            styles={{
              root: {
                width: "100px",
              },
            }}
            value={element?.value}
            onChange={(event) => {
              const value = event.target.value;
              const key = element?.metric;
              const newElements = elements.map((el) => {
                if (el.metric === key) {
                  return {
                    ...el,
                    value,
                  };
                }
                return el;
              });
              setElements(newElements);
              const newAttribute =
                key !== "weight"
                  ? {
                      ...payload?.attribute,
                      [key]: {
                        ...payload?.attribute?.[key],
                        oneDay: {
                          ...payload?.attribute?.[key]?.oneDay,
                          value: toNumber(value),
                        },
                      },
                    }
                  : {
                      ...payload?.attribute,
                      [key]: {
                        ...payload?.attribute?.[key],
                        value: toNumber(value),
                      },
                    };
              updateDefaultPODSetting({
                uid: payload.uid,
                payload: {
                  attribute: newAttribute,
                },
              });
            }}
          />
        )}
      </Table.Td>
    </Table.Tr>
  ));

  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  return (
    <div
      style={{
        width: "50%",
      }}
    >
      <Table
        stickyHeader
        stickyHeaderOffset={60}
        mt="md"
        horizontalSpacing="xl"
        __size="md"
        borderColor="gray"
        striped="even"
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>Chỉ số</Table.Th>
            <Table.Th style={headCellStyles}>1 Day</Table.Th>
            <Table.Th style={headCellStyles}>2 Days (liên tiếp)</Table.Th>
            <Table.Th style={headCellStyles}>Trọng số</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

export default DefineSku;
