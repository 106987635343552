import {
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
} from "../../../constant";

import { NewBriefsComponent } from "../../NewBriefs";

const OPTIMIZED_BRIEF_TYPES = [
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
];
const NewOptimizedBriefsScreen = () => {
  return (
    <NewBriefsComponent
      briefTypes={OPTIMIZED_BRIEF_TYPES}
      title="RnD - Optimized Brief Design"
    />
  );
};

export default NewOptimizedBriefsScreen;
