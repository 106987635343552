import {
  Table,
  ScrollArea,
  Group,
  Text,
  Image,
  ActionIcon,
} from "@mantine/core";
import { map } from "lodash";
import { IconTrash } from "@tabler/icons-react";

const PreviewTable = ({ data, setData }) => {
  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const rows = map(data, (item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Group gap="sm">
            <Image
              src={item.image || "/images/content/not_found_2.jpg"}
              alt={item.name}
              width="70px"
              height="70px"
              fit="cover"
            />
            <Text size="sm" fw={500}>
              {item.sku}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionIcon
              variant="filled"
              aria-label="Settings"
              color="red"
              onClick={() => {
                setData((prev) => prev.filter((i) => i.sku !== item.sku));
              }}
            >
              <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <ScrollArea>
      <Table miw={500} verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>SKU</Table.Th>
            <Table.Th style={headCellStyles}>Remove</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default PreviewTable;
