import { Flex, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
const InfoTooltip = ({ children, label }) => {
  return (
    <Tooltip label={label}>
      <Flex gap={5} align="center">
        <Flex direction="column" align={"center"}>
          {children}
        </Flex>
        <IconInfoCircle
          style={{
            width: 16,
            height: 16,
          }}
          color="#1c7ed6"
        />
      </Flex>
    </Tooltip>
  );
};

export default InfoTooltip;
