import { Button, Grid, Modal } from "@mantine/core";
import Editor from "../../../components/Editor";
import QuillEditor from "../../../components/QuillEditor";
import { getEditorStateAsString, getStringAsEditorState } from "../../../utils";
import { useEffect, useState } from "react";
import { campaignServices } from "../../../services";
import { showNotification } from "../../../utils/index";
import { isEmpty } from "lodash";

const ModalEditNoteForVideo = ({
  opened,
  close,
  selectedCampaign,
  setTrigger,
}) => {
  const [noteForVideo, setNoteForVideo] = useState(
    selectedCampaign?.briefInfo?.note?.noteForVideo || ""
  );
  useEffect(() => {
    if (selectedCampaign) {
      setNoteForVideo(selectedCampaign?.briefInfo?.note?.noteForVideo || "");
    }
  }, [selectedCampaign]);
  const [loading, setLoading] = useState(false);
  const updateNoteForVideo = async () => {
    setLoading(true);
    const updateNoteForVideoResponse = await campaignServices.updateVideoBrief(
      selectedCampaign?.briefInfo?.uid,
      {
        note: {
          ...selectedCampaign?.briefInfo?.note,
          noteForVideo: noteForVideo,
        },
      }
    );
    if (updateNoteForVideoResponse) {
      setTrigger(true);
      showNotification("Thành công", "Cập nhật Note thành công", "green");
      close();
    }
    setLoading(false);
  };
  return (
    <Modal
      opened={opened}
      onClose={close}
      transitionProps={{ transition: "fade", duration: 200 }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius="md"
      size="lg"
      title="Note For Video"
      styles={{
        title: {
          fontSize: 18,
          fontWeight: 700,
        },
      }}
    >
      <Grid>
        <Grid.Col span={12}>
          {/* <Editor state={noteForVideo} onChange={setNoteForVideo} /> */}
          <QuillEditor state={noteForVideo} onChange={setNoteForVideo} />
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              const note = noteForVideo;
              if (isEmpty(note)) {
                showNotification("Thất bại", "Không có gì thay đổi", "red");
                return;
              }
              updateNoteForVideo();
            }}
            loading={loading}
          >
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default ModalEditNoteForVideo;
