import { useCallback, useEffect, useState } from "react";
import { rndServices } from "../../services";
import usePagination from "../../hooks/usePagination";

const useFetchAllProducts = ({ search }) => {
  const { pagination, handleChangePage, setTotalPages } = usePagination();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await rndServices.getAllProducts({
        limit: 20,
        ...(search && { search }),
      });
      setData(result);
      setTotalPages(1);
    } catch (error) {
      if (error.message) {
        return setError(error.message);
      }
      setError("Unhandle error");
    } finally {
      setLoading(false);
    }
  }, [search, pagination.page]);

  useEffect(() => {
    getData();
  }, [search, pagination.page]);

  return {
    pagination,
    data,
    loading,
    error,
    handleChangePage,
    refetch: getData,
  };
};

export default useFetchAllProducts;
