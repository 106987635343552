export const OPTIMIZED_INFO_STATUS = {
  PRICE: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },

  SEEDING_POST: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  REVIEW_STORE: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  CUSTOM: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  TARGET_AND_BUDGET: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  REQUEST_VIDEO: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  ASC_AND_APS: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  STRATEGY: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  COGS_AND_ETA: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  GET_SAMPLE: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
  OPEN_VARIANT: {
    OK: 4,
    DONE: 3,
    CHECKED: 2,
    NOT_CHECKED: 1,
  },
};
export const OPTIMIZED_INFO_STATUS_NUMBER = {
  OK: 4,
  DONE: 3,
  CHECKED: 2,
  NOT_CHECKED: 1,
};
export const OPTIMIZED_INFO_NUMBER_TO_STATUS = {
  4: "Đã Ok",
  3: "Done",
  2: "Optimize",
};

export const OPTIMIZED_INFO_STATUS_TO_NUMBER = {
  "Đã Ok": 4,
  Done: 3,
  Optimize: 2,
};
