import { toNumber } from "lodash";
import useGetSampleList from "./useGetSampleList";
import { CURRENT_MONTH } from "../../../utils";

const useTable = ({ query, setQuery, endPoint }) => {
  const { data, refetch } = useGetSampleList({
    filters: query,
    endPoint,
  });

  const handleChangeDateRange = (value) => {
    setQuery({
      ...query,
      days: toNumber(value),
    });
  };

  const handleChangeMonth = (value) => {
    setQuery({
      ...query,
      month: toNumber(value),
      ...(toNumber(value) !== CURRENT_MONTH && {
        days: 31,
      }),
    });
  };

  const handleChangeTeams = (value) => {
    setQuery({
      ...query,
      teams: value,
    });
  };

  const onClearTeams = () => {
    setQuery({
      ...query,
      teams: [],
    });
  };

  return {
    data,
    refetch,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  };
};

export default useTable;
