import { useState } from "react";
import useFetchAllProducts from "./useFetchAllProducts";
import useFindSKU from "./useFindSKU";

const useFetchProducts = () => {
  const [searchSKU, setSearchSKU] = useState("");
  const { data, pagination } = useFetchAllProducts({
    search: searchSKU,
  });
  const { data: SKU = null, loading, refetch } = useFindSKU();
  const handleSubmitSKU = () => {
    refetch(searchSKU);
  };
  const handleChangeSKU = (value) => {
    setSearchSKU(value);
  };
  return {
    data,
    pagination,
    searchSKU,
    handleChangeSKU,
    loading,
    SKU,
    handleSubmitSKU,
  };
};

export default useFetchProducts;
