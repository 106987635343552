import {
  Button,
  Checkbox,
  Flex,
  Group,
  Image,
  Pagination,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconFilterOff,
  IconSearch,
  IconChevronRight,
  IconChevronDown,
  IconArchiveOff,
  IconArchive,
} from "@tabler/icons-react";
import { find, keys, values } from "lodash";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { DateRangePicker } from "rsuite";
import { CONVERT_STATUS_TO_NUMBER } from "../../../utils";
import useTable from "./useTable";

import formatDate from "../../../utils/formatDate";
import classes from "./index.module.css";
import Link from "../../../components/Link";
import EditLink from "../../../components/EditLink";
import Value from "../../../components/Value";
import SelectValue from "../../../components/SelectValue";
import { useDisclosure } from "@mantine/hooks";
import ModalRequestOldSKU from "../ModalRequestOldSKU";
import { useState } from "react";
import ModalEditNoteForVideo from "./NoteForVideo";
import { IconX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  BD_TEAMS,
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
  STATUS,
  SUPPLIERS,
} from "../../../constant";
import ModalEditNoteForVideoFromSample from "./NoteForVideoFromSample";
import CardInformation from "./CardInfomation";

const SampleTable = ({ query, setQuery, sorting, setSorting }) => {
  const [selectedBrief, setSelectedBrief] = useState({});
  const [openedNote, { open: openNote, close: closeNote }] =
    useDisclosure(false);

  const {
    data,
    searchSKU,
    pagination,
    clearFilters,
    handleChangeDate,
    handleChangePage,
    handleChangeSizeValue,
    handleChangeSKU,
    handleChangeStatus,
    handleClearSizeValue,
    handleClearSupplier,
    handleClearStatus,
    handleSubmitSKU,
    handleUpdateSupplier,
    handleDoneSample,
    handleIncompleteSample,
    handleUpdateLinkFileIn,
    handleUpdateValue,
    handleCreateRequest,
    handleRemoveSample,
    handleUpdateTeam,
    handleUpdateNote,
    handleChangeSupplier,
    handleUpdateFileSup,
    handleChangeFileSup,
    handleClearFileSup,
    handleArchiveBrief,
    loading,
    error,
  } = useTable({ query, setQuery, sorting, setSorting });

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Are you sure you want to delete this SKU?",
      centered: true,
      children: (
        <Text>
          Are you sure you want to delete {row.original.sku}? This action cannot
          be revert.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleRemoveSample(row.original.uid),
    });

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: { striped: "even" },
    columns: [
      {
        accessorKey: "videoRequestedDate",
        header: "DATE",
        size: 120,
        enableEditing: false,
        enableSorting: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell(row) {
          return formatDate(row.renderedCellValue);
        },
      },
      {
        accessorKey: "sku",
        header: "SKU",
        size: 120,
        enableEditing: false,
        enableExpanding: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          const sku = row.original?.sku;
          const videoOrder = row.original?.videoName;
          return (
            <Flex direction="column" align="center">
              <span>{sku}</span>
              {videoOrder && <span>{videoOrder}</span>}
            </Flex>
          );
        },
      },
      {
        accessorKey: "design",
        header: "DESIGN",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          return (
            <Image
              radius="md"
              src={
                row?.original?.designInfo?.thumbLink ||
                row?.original?.videoInfo?.thumbLink ||
                row?.original?.imageLink
              }
              height={100}
              fit="contain"
            />
          );
        },
      },
      {
        accessorKey: "numberOfBriefs",
        header: "SL File",
        size: 80,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
      },
      {
        accessorKey: "linkFileIn",
        header: "FILE IN",
        size: 120,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell({ row }) {
          const { isDisabled } = row.original;
          return isDisabled ? null : <Link href={row.original.videoFileIn} />;
        },
        Edit({ row }) {
          const { isDisabled } = row.original;
          return isDisabled ? null : (
            <EditLink
              onBlurSuccess={(value) => {
                handleUpdateLinkFileIn(row.original.uid, value);
              }}
            />
          );
        },
      },
      {
        accessorKey: "briefType",
        header: "Loại Brief",
        size: 130,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          const briefType = [
            BRIEF_TYPE_SAMPLE_VIDEO,
            BRIEF_TYPE_NEW_SAMPLE_VIDEO,
          ].includes(row.original.briefType)
            ? row.original.briefType
            : BRIEF_TYPE_ON_PLAN_VIDEO;
          return !row.original.briefType
            ? null
            : briefType?.replace("Video", "");
        },
      },
      {
        accessorKey: "note",
        header: "NOTE FROM SAMPLE",
        size: 100,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: (record) => {
          const row = record?.row.original;
          const note = row?.note?.noteForVideo;
          const isDisabled = row?.isDisabled;
          return isDisabled ? null : (
            <Button
              onClick={() => {
                setSelectedBrief(row);
                openNote();
              }}
              variant="filled"
              color={note ? "green" : "gray"}
              size="sx"
            >
              Note
            </Button>
          );
        },
      },
      {
        accessorKey: "noteFromSampleForVideo",
        header: "NOTE FOR VIDEO",
        size: 100,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableEditing: false,
        enableSorting: false,
        Cell: (record) => {
          const row = record?.row.original;
          const note = row?.note?.noteFromSampleForVideo;
          const isDisabled = row?.isDisabled;
          return isDisabled ? null : (
            <Button
              onClick={() => {
                setSelectedBrief(row);
                openModalNoteFromSample();
              }}
              variant="filled"
              color={note ? "green" : "gray"}
              size="sx"
            >
              Note
            </Button>
          );
        },
      },
      {
        accessorKey: "value",
        header: "VALUE",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["SKU"] },
        enableSorting: false,
        Cell: ({ row }) => {
          const value = row?.original?.value;
          return <Value value={value} />;
        },
        Edit: ({ row }) => {
          const isDisabled = row?.original?.isDisabled;
          const value = row?.original?.value;
          return isDisabled ? (
            <Value value={value} />
          ) : (
            <SelectValue
              uid={row?.original?.uid}
              defaultValue={row?.original?.value}
              handleChange={handleUpdateValue}
            />
          );
        },
      },
      {
        accessorKey: "rndTeam",
        header: "Team",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Edit: ({ row }) => {
          const isDisabled = row?.original?.isDisabled;
          const rndTeam = row?.original?.rndTeam;
          return isDisabled ? (
            rndTeam
          ) : (
            <Select
              defaultValue={row.original.rndTeam}
              onChange={(value) => handleUpdateTeam(row.original?.uid, value)}
              placeholder="Pick value"
              data={BD_TEAMS}
              allowDeselect={false}
            />
          );
        },
      },
      {
        header: "Sup/UID quay",
        size: 170,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell({ row }) {
          const videoSupplier = row?.original?.videoSupplier;
          const uid = row?.original?.uid;
          const isDisabled = row?.original?.isDisabled;
          return isDisabled ? null : (
            <Select
              value={videoSupplier || null}
              onChange={(value) => handleUpdateSupplier(uid, value)}
              placeholder="Pick value"
              data={values(SUPPLIERS)}
              allowDeselect={false}
            />
          );
        },
      },
      {
        header: "FILE SUP",
        size: 40,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell({ row }) {
          const isFileSup = row?.original?.isFileSup || false;
          const uid = row?.original?.uid;
          const isDisabled = row?.original?.isDisabled;
          return isDisabled ? null : (
            <Group
              style={{
                justifyContent: "center",
              }}
            >
              <Checkbox
                checked={isFileSup}
                onChange={() => handleUpdateFileSup(uid, !isFileSup)}
                size="lg"
              />
            </Group>
          );
        },
      },
      {
        header: "DONE",
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell({ row }) {
          const isDone = row.original.videoStatus === 2;
          const isUID = row.original.videoSupplier === "UID";
          const isDisabled = row?.original?.isDisabled;
          return isDisabled ? null : (
            <Button
              onClick={() => {
                if (isDone) {
                  handleIncompleteSample(row.original.uid);
                } else {
                  handleDoneSample(row.original.uid);
                }
              }}
              color="green"
              variant={isDone ? "default" : "filled"}
              disabled={!row.original.videoSupplier}
            >
              {isUID ? "Có Sample" : "Có source Video"}
            </Button>
          );
        },
      },
      {
        accessorKey: "timeVideoCreated",
        header: "TIME",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: true,
        Cell(record) {
          const isDelaySource = record?.row?.original?.isDelaySource;
          return (
            <span
              style={{
                color: isDelaySource ? "red" : "black",
              }}
            >
              {record?.row?.original?.videoSampleInfo?.time ||
                record?.row?.original?.totalTime}
            </span>
          );
        },
      },
      {
        accessorKey: "remove",
        header: "ACTIONS",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="filled"
                color="red"
                size="sx"
                onClick={() => {
                  openDeleteConfirmModal(row);
                }}
              >
                <IconX />
              </Button>
            </div>
          );
        },
      },
      {
        accessorKey: "archive",
        header: "ARCHIVE",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell: ({ row }) => {
          const archive = row?.original?.archive;
          const isDisabled = row?.original?.isDisabled;
          const updateStatus =
            archive !== STATUS.ARCHIVED ? STATUS.ARCHIVED : STATUS.UN_ARCHIVED;
          return isDisabled ? null : (
            <Button
              variant="filled"
              color={archive === STATUS.ARCHIVED ? "red" : "green"}
              onClick={() => {
                openArchiveConfirmModal(row?.original, updateStatus);
              }}
            >
              {archive === STATUS.ARCHIVED ? (
                <Tooltip label="Un-Archive">
                  <IconArchiveOff />
                </Tooltip>
              ) : (
                <Tooltip label="Archive">
                  <IconArchive />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ],
    data: data?.data || [],
    editDisplayMode: "cell",
    enableEditing: true,
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    enableSorting: true,
    enableExpanding: true,
    enableExpandAll: false,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
          }}
        >
          <Flex
            style={{
              gap: "8px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
            }}
          >
            <TextInput
              placeholder="SKU"
              size="sm"
              width="100px"
              leftSection={
                <span
                  onClick={handleSubmitSKU}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={searchSKU}
              onChange={handleChangeSKU}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmitSKU();
                }
              }}
            />
            <DateRangePicker
              size="sx"
              placeholder="Date"
              style={{
                width: "200px",
              }}
              value={query.dateValue}
              onOk={(value) => handleChangeDate(value, value[0], value[1])}
              onClean={() => handleChangeDate(null, null, null)}
              onShortcutClick={(shortcut) => {
                const { value } = shortcut;
                handleChangeDate(value, value[0], value[1]);
              }}
            />
            <Select
              clearable
              placeholder="Sup"
              data={[...values(SUPPLIERS), "NA"]}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.videoSupplierValue}
              onChange={(value) => handleChangeSupplier(value)}
              onClear={handleClearSupplier}
            />
            <Select
              clearable
              placeholder="Value"
              data={keys(CONVERT_STATUS_TO_NUMBER)}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.valueName}
              onChange={(value) => handleChangeSizeValue(value)}
              onClear={handleClearSizeValue}
            />
            <Select
              clearable
              placeholder="FILE SUP"
              data={["Done", "Chưa Done", "All"]}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.isFileSupValue || "All"}
              onChange={(value) => handleChangeFileSup(value)}
              onClear={handleClearFileSup}
            />
            <Select
              clearable
              placeholder="Status"
              data={["Done", "Undone", "Archived"]}
              styles={{
                input: {
                  width: "120px",
                },
              }}
              value={query?.statusValue}
              onChange={handleChangeStatus}
              onClear={handleClearStatus}
            />
            <Button onClick={clearFilters}>
              <IconFilterOff />
            </Button>
          </Flex>
          <Flex>
            <Button
              onClick={() => {
                open();
              }}
            >
              Request Optimize/New
            </Button>
          </Flex>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkFileIn" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    }),
    mantineExpandButtonProps: ({ row }) => {
      const isExpanded = row?.getIsExpanded();
      return {
        children: isExpanded ? (
          <IconChevronDown color="#228be6" />
        ) : (
          <IconChevronRight />
        ),
      };
    },
    enableStickyHeader: true,
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [
    openedModalNoteFromSample,
    { open: openModalNoteFromSample, close: closeModalNoteFromSample },
  ] = useDisclosure(false);
  // CONFIRM ARCHIVE
  const openArchiveConfirmModal = (payload, status) =>
    modals.openConfirmModal({
      title: "Confirm Modal",
      centered: true,
      children: (
        <Text>Are you sure you want to call this action {payload?.sku}</Text>
      ),
      labels: { confirm: "Okay", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleArchiveBrief(payload?.uid, status),
    });
  return (
    <div>
      <CardInformation {...data?.metadata} />
      <MantineReactTable table={table} />
      <Flex justify="flex-end">
        {pagination.totalPages && (
          <Pagination
            total={pagination.totalPages}
            value={pagination.page}
            onChange={handleChangePage}
            color="pink"
            size="md"
            style={{ marginTop: "20px", marginLeft: "auto" }}
          />
        )}
      </Flex>
      <ModalRequestOldSKU
        opened={opened}
        close={close}
        createRequest={handleCreateRequest}
        loading={loading}
        error={error}
      />
      {openedNote && selectedBrief && (
        <ModalEditNoteForVideo
          opened={openedNote}
          close={closeNote}
          selectedBrief={selectedBrief}
          handleUpdateNote={handleUpdateNote}
        />
      )}
      {openedModalNoteFromSample && selectedBrief && (
        <ModalEditNoteForVideoFromSample
          opened={openedModalNoteFromSample}
          close={closeModalNoteFromSample}
          selectedBrief={selectedBrief}
          handleUpdateNote={handleUpdateNote}
        />
      )}
    </div>
  );
};

export default SampleTable;
