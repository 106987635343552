import { TagsInput } from "@mantine/core";
import { useEffect, useRef } from "react";
import { showNotification } from "../../utils/index";
import { uniq } from "lodash";

const EditMultipleLinkImages = ({
  value,
  onChange,
  onBlurError,
  onBlurSuccess,
  readOnly,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (!inputRef) return;
    inputRef.current.focus();
  }, []);

  return (
    <TagsInput
      ref={inputRef}
      value={value}
      splitChars={[",", " ", "|", "\r\n", "\n"]}
      clearable
      withScrollArea={true}
      styles={{
        input: {
          maxWidth: "300px",
        },
      }}
      maxDropdownHeight="100px"
      onChange={(value) => {
        onChange?.(value);
        const allValidLinks = uniq([...value]);
        onBlurSuccess?.(allValidLinks);
      }}
      readOnly={readOnly}
    />
  );
};

export default EditMultipleLinkImages;
