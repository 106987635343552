import { Button, Grid, Group, Modal } from "@mantine/core";
import Editor from "../../../components/Editor";
import QuillEditor from "../../../components/QuillEditor";
import { getEditorStateAsString, getStringAsEditorState } from "../../../utils";
import styles from "./NoteForVideo.module.sass";
import { useEffect, useState } from "react";
import { campaignServices } from "../../../services";
import { showNotification } from "../../../utils/index";
import { isEmpty } from "lodash";

const ModalEditNoteForVideo = ({
  opened,
  close,
  selectedBrief,
  handleUpdateNote,
}) => {
  const [loading, setLoading] = useState(false);
  const [videoNote, setVideoNote] = useState(
    selectedBrief?.note?.noteForVideo || ""
  );
  useEffect(() => {
    if (!isEmpty(selectedBrief)) {
      setVideoNote(selectedBrief?.note?.noteForVideo || "");
    }
  }, [selectedBrief]);

  // const handleUpdateNote = async () => {
  //   setLoading(true);
  //   const updateNoteResponse = await campaignServices.updateVideoSampleBrief(
  //     selectedBrief.uid,
  //     {
  //       note: {
  //         ...selectedBrief.note,
  //         noteForVideo: getEditorStateAsString(videoNote),
  //       },
  //     }
  //   );
  //   if (updateNoteResponse) {
  //     showNotification("Thành công", "Cập nhật Note thành công", "green");
  //     close();
  //   }
  //   setLoading(false);
  // };

  return (
    <Modal
      opened={opened}
      onClose={close}
      transitionProps={{ transition: "fade", duration: 200 }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius="md"
      size="lg"
      title="Note From MKT"
      styles={{
        title: {
          fontSize: 18,
          fontWeight: 700,
        },
      }}
    >
      <Grid>
        <Grid.Col span={12}>
          <QuillEditor
            state={videoNote}
            onChange={setVideoNote}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Group
            style={{
              justifyContent: "center",
            }}
          >
            <Button
              loading={loading}
              onClick={() => {
                setLoading(true);
                handleUpdateNote(selectedBrief.uid, {
                  note: {
                    ...selectedBrief.note,
                    noteForVideo: videoNote,
                  },
                });
                setLoading(false);
                close();
              }}
            >
              Confirm
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default ModalEditNoteForVideo;
