import React, { useEffect, useState } from "react";
import styles from "./Sellerboard.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import {
  ActionIcon,
  Flex,
  Grid,
  Group,
  Loader,
  Modal,
  Radio,
  Switch,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { amzServices } from "../../services";
import Table from "./Table";
import { includes, isEmpty, map, omit, toNumber, uniq } from "lodash";
import SurvivalModeTable from "./SurvivalMode";
import moment from "moment-timezone";
import OptimizedTableMode from "./OptimizeMode";
import {
  FilterNormalModeHeader,
  FilterOptimizedModeHeader,
  FilterSurvivalModeHeader,
  generateSortingAndQueryBaseOnActiveTab,
} from "./utils";
import { IconCirclePlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import SearchSKU from "./PreviewTableAddSKU";
import {
  AMZ_DASHBOARD_STATUS,
  CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER,
  FULFILLMENT_CHANNELS,
} from "../../constant";

const TABS_VIEW = {
  Date: "Date",
  Week: "Week",
  Month: "Month",
  SURVIVAL: "Survival",
  OPTIMIZE: "Optimize",
};

const TARGET_DATES = {
  ONE_DAY: "1 Days",
  THREE_DAYS: "3 Days",
  SEVEN_DAYS: "7 Days",
};

const moveIdsToStart = (array, ids) => {
  return array.sort((a, b) => {
    if (ids.includes(a.sku) && !ids.includes(b.sku)) {
      return -1;
    }
    if (!ids.includes(a.sku) && ids.includes(b.sku)) {
      return 1;
    }
    return 0;
  });
};

const handleStateWhenActiveTab = ({
  setSorting,
  setQuery,
  setSaleMetrics,
  setIsConfirmedQuery,
  activeTab,
  setActiveTab,
  oneMonthAgo,
  endDate,
  currentYear,
  currentWeek,
}) => {
  setSaleMetrics([]);
  setIsConfirmedQuery(true);
  setActiveTab(activeTab);
  const { query, sorting } = generateSortingAndQueryBaseOnActiveTab({
    activeTab,
    oneMonthAgo,
    endDate,
    TARGET_DATES,
    TABS_VIEW,
    currentYear,
    currentWeek,
  });
  setQuery(query);
  setSorting(sorting);
};

const Sellerboard = () => {
  const [isConfirmedQuery, setIsConfirmedQuery] = useState(true);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [saleMetrics, setSaleMetrics] = useState([]);
  const initialPage = 1;
  const [overrideMetrics, setOverrideMetrics] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: initialPage,
    totalPages: 1,
  });
  const oneMonthAgo = moment()
    .tz("America/Los_Angeles")
    .subtract(1, "month")
    .format("YYYY-MM-DD");
  const currentWeek = moment().tz("America/Los_Angeles").week();
  const currentYear = moment().tz("America/Los_Angeles").year();
  const endDate = moment().tz("America/Los_Angeles").format("YYYY-MM-DD");
  const [query, setQuery] = useState({
    stores: "QZL",
    storeValues: ["QZL"],
    primarySortBy: "totalOrders7d",
    primarySortDir: "desc",
    optimizedList: [
      AMZ_DASHBOARD_STATUS.NOT_TOUCH,
      AMZ_DASHBOARD_STATUS.OPTIMIZED,
    ],
    optimized: [
      CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[AMZ_DASHBOARD_STATUS.NOT_TOUCH],
      CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[AMZ_DASHBOARD_STATUS.OPTIMIZED],
    ],
    fulfillmentChannel: FULFILLMENT_CHANNELS,
  });
  const [sorting, setSorting] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [listingDays, setListingDays] = useState("");
  const [adDaysNum, setAdDaysNum] = useState("30");
  const [metadata, setMetadata] = useState({});
  const [loadingFetchSaleMetrics, setLoadingFetchSaleMetrics] = useState(true);
  const fetchSaleMetrics = async (page, queryInput, sortingInput) => {
    setLoadingFetchSaleMetrics(true);
    const realQuery = !isEmpty(queryInput) ? queryInput : query;
    const realSorting = !isEmpty(sortingInput) ? sortingInput : sorting;
    const response = await amzServices.fetchSaleMetrics({
      page,
      query: omit(
        {
          ...realQuery,
          ...(realQuery?.ordersInRange &&
            realQuery?.startDate &&
            realQuery?.endDate && {
              startDate: realQuery.startDate,
              endDate: realQuery.endDate,
              ordersInRange: toNumber(realQuery.ordersInRange),
            }),
          ...(realQuery?.minOrders && {
            minOrders: toNumber(realQuery.minOrders),
          }),
        },
        [
          "sortValue",
          "storeValues",
          "dateValue",
          "isConfirmed",
          "fulfillmentChannelValues",
          "salesDateValue",
          "targetDate",
        ]
      ),
      limit: 20,
      sorting: realSorting,
    });
    const { data, metaData } = response;
    if (data) {
      if (isLoadmore) {
        const oldSaleMetrics = map(saleMetrics, (x) => {
          if (includes(overrideMetrics, x.sku)) {
            return {
              ...x,
              optimized: 1,
            };
          }
          return x;
        });
        const newSaleMetrics = [...oldSaleMetrics, ...data];
        const sortedSaleMetrics = moveIdsToStart(
          newSaleMetrics,
          uniq(overrideMetrics)
        );
        setSaleMetrics(sortedSaleMetrics);
      } else {
        const sortedSaleMetrics = moveIdsToStart(data, uniq(overrideMetrics));
        setSaleMetrics(sortedSaleMetrics);
      }
      setPagination({
        currentPage: toNumber(metaData.currentPage) || 1,
        totalPages: toNumber(metaData.totalPages) || 1,
      });
    } else {
      setSaleMetrics([]);
    }
    setIsConfirmedQuery(false);
    setIsLoadmore(false);
    setLoadingFetchSaleMetrics(false);
    setTrigger(false);
  };
  const fetchSaleMetricsForSurvivalMode = async (
    page,
    queryInput,
    sortingInput
  ) => {
    setLoadingFetchSaleMetrics(true);
    const realQuery = !isEmpty(queryInput) ? queryInput : query;
    const realSorting = !isEmpty(sortingInput) ? sortingInput : sorting;
    const response = await amzServices.fetchSurvivalModeSaleMetrics({
      page,
      query: omit(
        {
          ...realQuery,
        },
        [
          "sortValue",
          "storeValues",
          "dateValue",
          "isConfirmed",
          "fulfillmentChannelValues",
          "salesDateValue",
          "value",
        ]
      ),
      limit: 50,
      sorting: realSorting,
    });
    const { data, metaData } = response;
    if (data) {
      if (isLoadmore) {
        setSaleMetrics((prev) => [...prev, ...data]);
      } else {
        setSaleMetrics(data);
      }
      setPagination({
        currentPage: toNumber(metaData.currentPage) || 1,
        totalPages: toNumber(metaData.totalPages) || 1,
      });
    } else {
      setSaleMetrics([]);
    }
    setIsConfirmedQuery(false);
    setLoadingFetchSaleMetrics(false);
    setTrigger(false);
    setIsLoadmore(false);
  };
  const fetchOptimizedSaleMetrics = async (page, queryInput, sortingInput) => {
    setLoadingFetchSaleMetrics(true);
    const realQuery = !isEmpty(queryInput) ? queryInput : query;
    const realSorting = !isEmpty(sortingInput) ? sortingInput : sorting;
    const response = await amzServices.fetchAllOptimizedReports({
      page,
      query: omit(
        {
          ...realQuery,
          ...(realQuery?.value && {
            value: [realQuery?.value],
          }),
          ...(realQuery?.pic && {
            pic: [realQuery?.pic],
          }),
        },
        [
          "sortValue",
          "storeValues",
          "dateValue",
          "isConfirmed",
          "fulfillmentChannelValues",
          "salesDateValue",
        ]
      ),
      limit: 20,
      sorting: realSorting,
    });
    const { data, metadata } = response;
    if (data) {
      if (isLoadmore) {
        setSaleMetrics((prev) => [...prev, ...data]);
      } else {
        setSaleMetrics(data);
      }
      setPagination({
        currentPage: toNumber(metadata?.currentPage) || 1,
        totalPages: toNumber(metadata?.totalPages) || 1,
      });
      setMetadata(metadata);
    } else {
      setSaleMetrics([]);
    }
    setIsConfirmedQuery(false);
    setLoadingFetchSaleMetrics(false);
    setTrigger(false);
    setIsLoadmore(false);
  };
  const [activeTab, setActiveTab] = useState(TABS_VIEW.OPTIMIZE);

  useEffect(() => {
    if (isConfirmedQuery) {
      switch (activeTab) {
        case TABS_VIEW.Date:
          fetchSaleMetrics(pagination.currentPage, query, sorting);
          break;
        case TABS_VIEW.Week:
          fetchSaleMetrics(pagination.currentPage, query, sorting);
          break;
        case TABS_VIEW.Month:
          fetchSaleMetrics(pagination.currentPage, query, sorting);
          break;
        case TABS_VIEW.SURVIVAL:
          fetchSaleMetricsForSurvivalMode(
            pagination.currentPage,
            query,
            sorting
          );
          break;
        case TABS_VIEW.OPTIMIZE:
          fetchOptimizedSaleMetrics(pagination.currentPage, query, sorting);
          break;
        default:
          fetchSaleMetrics(pagination.currentPage, query, sorting);
          break;
      }
    }
  }, [
    query,
    trigger,
    sorting,
    isConfirmedQuery,
    pagination.currentPage,
    activeTab,
  ]);

  // listen sorting change set isConfirmedQuery to true for refetch data
  useEffect(() => {
    if (!isEmpty(sorting)) {
      setPagination({
        ...pagination,
        currentPage: 1,
      });
      setIsConfirmedQuery(true);
    }
  }, [sorting]);

  return (
    <>
      <div>
        <Card
          className={styles.card}
          title="AMZ Sellerboard"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={cn(styles.head)}
          head={
            <Flex
              style={{
                gap: "8px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#EFF0F1",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ActionIcon
                  variant="filled"
                  aria-label="Settings"
                  onClick={open}
                  label="Add Optimize SKU"
                  size="lg"
                >
                  <Tooltip label="Add Optimize SKU">
                    <IconCirclePlus
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </Tooltip>
                </ActionIcon>
                <Text
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    verticalAlign: "middle",
                  }}
                >
                  Thêm SKU
                </Text>
              </div>
              <Switch
                checked={activeTab === TABS_VIEW.OPTIMIZE}
                label={TABS_VIEW.OPTIMIZE}
                onChange={(event) => {
                  const value = event.currentTarget.checked;
                  handleStateWhenActiveTab({
                    setSorting,
                    setQuery,
                    setSaleMetrics,
                    setIsConfirmedQuery,
                    activeTab: value ? TABS_VIEW.OPTIMIZE : TABS_VIEW.Date,
                    setActiveTab,
                    oneMonthAgo,
                    endDate,
                    currentYear,
                    currentWeek,
                  });
                }}
                style={{
                  cursor: "pointer",
                }}
              />
              <Switch
                checked={activeTab === TABS_VIEW.SURVIVAL}
                label={TABS_VIEW.SURVIVAL}
                onChange={(event) => {
                  const value = event.currentTarget.checked;
                  handleStateWhenActiveTab({
                    setSorting,
                    setQuery,
                    setSaleMetrics,
                    setIsConfirmedQuery,
                    activeTab: value ? TABS_VIEW.SURVIVAL : TABS_VIEW.Date,
                    setActiveTab,
                    oneMonthAgo,
                    endDate,
                    currentYear,
                    currentWeek,
                  });
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </Flex>
          }
        >
          <Grid>
            <Grid.Col span="12">
              <Tabs
                value={activeTab}
                onChange={(value) => {
                  handleStateWhenActiveTab({
                    setSorting,
                    setQuery,
                    setSaleMetrics,
                    setIsConfirmedQuery,
                    activeTab: value,
                    setActiveTab,
                    oneMonthAgo,
                    endDate,
                    currentYear,
                    currentWeek,
                  });
                }}
                style={{
                  width: "100%",
                }}
              >
                <Tabs.List>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 0px",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Flex
                      style={{
                        gap: "8px",
                        padding: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#EFF0F1",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <Tabs.Tab
                        value={TABS_VIEW.Date}
                        disabled={includes(
                          [TABS_VIEW.SURVIVAL, TABS_VIEW.OPTIMIZE],
                          activeTab
                        )}
                        styles={{
                          ...(activeTab === TABS_VIEW.Date && {
                            tab: {
                              backgroundColor: "#7C4DFF",
                              color: "#fff",
                              borderRadius: "10px",
                              borderColor: "transparent",
                            },
                          }),
                        }}
                      >
                        {TABS_VIEW.Date}
                      </Tabs.Tab>
                      <Tabs.Tab
                        value={TABS_VIEW.Week}
                        disabled={includes(
                          [TABS_VIEW.SURVIVAL, TABS_VIEW.OPTIMIZE],
                          activeTab
                        )}
                        styles={{
                          ...(activeTab === TABS_VIEW.Week && {
                            tab: {
                              backgroundColor: "#7C4DFF",
                              color: "#fff",
                              borderRadius: "10px",
                              borderColor: "transparent",
                            },
                          }),
                        }}
                      >
                        {TABS_VIEW.Week}
                      </Tabs.Tab>
                      <Tabs.Tab
                        value={TABS_VIEW.Month}
                        disabled={includes(
                          [TABS_VIEW.SURVIVAL, TABS_VIEW.OPTIMIZE],
                          activeTab
                        )}
                        styles={{
                          ...(activeTab === TABS_VIEW.Month && {
                            tab: {
                              backgroundColor: "#7C4DFF",
                              color: "#fff",
                              borderRadius: "10px",
                              borderColor: "transparent",
                            },
                          }),
                        }}
                      >
                        {TABS_VIEW.Month}
                      </Tabs.Tab>
                      {activeTab === TABS_VIEW.OPTIMIZE && (
                        <Group>
                          <Text
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            TB Tồn Time: {metadata?.averageTime}{" "}
                            {metadata?.averageTime > 1 ? "days" : "day"}
                          </Text>
                          <Text
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Số Card: {metadata?.totalRecords || 0}{" "}
                          </Text>
                        </Group>
                      )}
                      {activeTab === TABS_VIEW.Date && (
                        <Grid.Col
                          span={4}
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginRight: "2px",
                            padding: "10px",
                            backgroundColor: "#e2eaff",
                            border: "1px solid #4f80ff",
                            borderColor: "#4f80ff",
                          }}
                        >
                          <Radio.Group
                            value={query?.targetDate}
                            onChange={(value) => {
                              setPagination({
                                ...pagination,
                                currentPage: 1,
                              });
                              setIsConfirmedQuery(true);
                              switch (value) {
                                case TARGET_DATES.SEVEN_DAYS:
                                  setQuery({
                                    ...query,
                                    dateRange: 7,
                                    targetDate: value,
                                  });
                                  break;
                                case TARGET_DATES.ONE_DAY:
                                  setQuery({
                                    ...query,
                                    dateRange: 1,
                                    targetDate: value,
                                  });
                                  break;
                                case TARGET_DATES.THREE_DAYS:
                                  setQuery({
                                    ...query,
                                    dateRange: 3,
                                    targetDate: value,
                                  });
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            <Group
                              styles={{
                                root: {
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <Radio
                                styles={{
                                  input: {
                                    borderRadius: "50%",
                                  },
                                }}
                                value={TARGET_DATES.ONE_DAY}
                                label={TARGET_DATES.ONE_DAY}
                              />
                              <Radio
                                styles={{
                                  input: {
                                    borderRadius: "50%",
                                  },
                                }}
                                value={TARGET_DATES.THREE_DAYS}
                                label={TARGET_DATES.THREE_DAYS}
                              />
                              <Radio
                                styles={{
                                  input: {
                                    borderRadius: "50%",
                                  },
                                }}
                                value={TARGET_DATES.SEVEN_DAYS}
                                label={TARGET_DATES.SEVEN_DAYS}
                              />
                            </Group>
                          </Radio.Group>
                        </Grid.Col>
                      )}
                      <Grid.Col
                        span={activeTab === TABS_VIEW.Date ? 4.5 : 6}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                          marginRight: "2px",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Text
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          All SKU
                        </Text>
                        <Switch
                          checked={query?.toggleTest}
                          label="SKU Test"
                          disabled={includes(
                            [TABS_VIEW.SURVIVAL, TABS_VIEW.OPTIMIZE],
                            activeTab
                          )}
                          onChange={() => {
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            if (query?.toggleTest) {
                              setListingDays("");
                              setAdDaysNum("");
                            } else {
                              setAdDaysNum("30");
                            }
                            setIsConfirmedQuery(true);
                            setQuery({
                              ...query,
                              toggleTest: !query.toggleTest,
                              ...(query.toggleTest && {
                                adDays: null,
                                listingDays: null,
                              }),
                            });
                          }}
                          styles={{
                            label: {
                              fontSize: "14px",
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </Grid.Col>
                    </Flex>
                  </div>
                </Tabs.List>
                <Tabs.Panel value={TABS_VIEW.Date}>
                  {activeTab === TABS_VIEW.Date && !isEmpty(saleMetrics) && (
                    <Table
                      className={styles.Table}
                      tableData={saleMetrics}
                      query={query}
                      setQuery={setQuery}
                      loading={loadingFetchSaleMetrics}
                      setTrigger={setTrigger}
                      setSorting={setSorting}
                      sorting={sorting}
                      activeTab={activeTab}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      setPagination={setPagination}
                      pagination={pagination}
                      setIsLoadmore={setIsLoadmore}
                      overrideMetrics={overrideMetrics}
                      setOverrideMetrics={setOverrideMetrics}
                      setTableData={setSaleMetrics}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                  {loadingFetchSaleMetrics && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  )}
                  {isEmpty(saleMetrics) && !loadingFetchSaleMetrics && (
                    <FilterNormalModeHeader
                      query={query}
                      setQuery={setQuery}
                      activeTab={activeTab}
                      setIsLoadmore={setIsLoadmore}
                      setPagination={setPagination}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      loading={loadingFetchSaleMetrics}
                      pagination={pagination}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value={TABS_VIEW.Week}>
                  {activeTab === TABS_VIEW.Week && !isEmpty(saleMetrics) && (
                    <Table
                      className={styles.Table}
                      tableData={saleMetrics}
                      query={query}
                      setQuery={setQuery}
                      loading={loadingFetchSaleMetrics}
                      setTrigger={setTrigger}
                      setSorting={setSorting}
                      sorting={sorting}
                      activeTab={activeTab}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      setPagination={setPagination}
                      pagination={pagination}
                      setIsLoadmore={setIsLoadmore}
                      overrideMetrics={overrideMetrics}
                      setOverrideMetrics={setOverrideMetrics}
                      setTableData={setSaleMetrics}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                  {loadingFetchSaleMetrics && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  )}
                  {isEmpty(saleMetrics) && !loadingFetchSaleMetrics && (
                    <FilterNormalModeHeader
                      query={query}
                      setQuery={setQuery}
                      activeTab={activeTab}
                      setIsLoadmore={setIsLoadmore}
                      setPagination={setPagination}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      loading={loadingFetchSaleMetrics}
                      pagination={pagination}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value={TABS_VIEW.Month}>
                  {activeTab === TABS_VIEW.Month && !isEmpty(saleMetrics) && (
                    <Table
                      className={styles.Table}
                      tableData={saleMetrics}
                      query={query}
                      setQuery={setQuery}
                      loading={loadingFetchSaleMetrics}
                      setTrigger={setTrigger}
                      setSorting={setSorting}
                      sorting={sorting}
                      activeTab={activeTab}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      setPagination={setPagination}
                      pagination={pagination}
                      setIsLoadmore={setIsLoadmore}
                      overrideMetrics={overrideMetrics}
                      setOverrideMetrics={setOverrideMetrics}
                      setTableData={setSaleMetrics}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                  {loadingFetchSaleMetrics && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  )}
                  {isEmpty(saleMetrics) && !loadingFetchSaleMetrics && (
                    <FilterNormalModeHeader
                      query={query}
                      setQuery={setQuery}
                      activeTab={activeTab}
                      setIsLoadmore={setIsLoadmore}
                      setPagination={setPagination}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      loading={loadingFetchSaleMetrics}
                      pagination={pagination}
                      setListingDays={setListingDays}
                      setAdDaysNum={setAdDaysNum}
                      adDaysNum={adDaysNum}
                      listingDays={listingDays}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value={TABS_VIEW.SURVIVAL}>
                  {activeTab === TABS_VIEW.SURVIVAL &&
                    !isEmpty(saleMetrics) && (
                      <SurvivalModeTable
                        className={styles.Table}
                        tableData={saleMetrics}
                        query={query}
                        setQuery={setQuery}
                        loading={loadingFetchSaleMetrics}
                        setTrigger={setTrigger}
                        setSorting={setSorting}
                        sorting={sorting}
                        activeTab={activeTab}
                        setPagination={setPagination}
                        pagination={pagination}
                        setIsConfirmedQuery={setIsConfirmedQuery}
                        setIsLoadmore={setIsLoadmore}
                      />
                    )}
                  {loadingFetchSaleMetrics && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  )}
                  {isEmpty(saleMetrics) && !loadingFetchSaleMetrics && (
                    <FilterSurvivalModeHeader
                      query={query}
                      setQuery={setQuery}
                      activeTab={activeTab}
                      setIsLoadmore={setIsLoadmore}
                      setPagination={setPagination}
                      setIsConfirmedQuery={setIsConfirmedQuery}
                      loading={loadingFetchSaleMetrics}
                      pagination={pagination}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value={TABS_VIEW.OPTIMIZE}>
                  <FilterOptimizedModeHeader
                    query={query}
                    setQuery={setQuery}
                    activeTab={activeTab}
                    setIsLoadmore={setIsLoadmore}
                    setPagination={setPagination}
                    setIsConfirmedQuery={setIsConfirmedQuery}
                    loading={loadingFetchSaleMetrics}
                    pagination={pagination}
                    setListingDays={setListingDays}
                    setAdDaysNum={setAdDaysNum}
                    adDaysNum={adDaysNum}
                    listingDays={listingDays}
                  />
                  {activeTab === TABS_VIEW.OPTIMIZE &&
                    !isEmpty(saleMetrics) && (
                      <OptimizedTableMode
                        className={styles.Table}
                        tableData={saleMetrics}
                        query={query}
                        setQuery={setQuery}
                        loading={loadingFetchSaleMetrics}
                        setTrigger={setTrigger}
                        setSorting={setSorting}
                        sorting={sorting}
                        activeTab={activeTab}
                        setPagination={setPagination}
                        pagination={pagination}
                        setIsConfirmedQuery={setIsConfirmedQuery}
                        setIsLoadmore={setIsLoadmore}
                      />
                    )}
                  {loadingFetchSaleMetrics && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  )}
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>
          </Grid>
        </Card>
        {opened && (
          <Modal
            opened={opened}
            onClose={close}
            transitionProps={{ transition: "fade", duration: 200 }}
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            radius="md"
            size="lg"
            title="Thêm SKU"
          >
            <SearchSKU close={close} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Sellerboard;
