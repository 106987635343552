import { Badge } from "@mantine/core";
import { CONVERT_NUMBER_TO_STATUS } from "../../../../utils";

const Value = ({ value, onDoubleClick }) => {
  let color = null;
  switch (value) {
    case 1:
      color = "#cfcfcf";
      break;
    case 2:
      color = "yellow";
      break;
    case 3:
      color = "green";
      break;
    case 4:
      color = "#38761C";
      break;
    default:
      break;
  }
  return color ? (
    <Badge color={color} variant="filled" onDoubleClick={onDoubleClick}>
      {CONVERT_NUMBER_TO_STATUS[value]}
    </Badge>
  ) : (
    <span>{CONVERT_NUMBER_TO_STATUS[value]}</span>
  );
};

export default Value;
