import {
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  Radio,
  Select,
  TextInput,
} from "@mantine/core";
import SearchSKU from "../../../components/SearchSKU";
import { useState } from "react";
import PreviewTable from "./PreviewTable";
import { isEmpty, map, toNumber } from "lodash";
import {
  BD_TEAMS,
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
  STATUS,
} from "../../../constant";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../../utils";
import NoteRequest from "../Table/NoteRequest";

const BRIEF_TYPES = {
  NEW: BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  OPTMIZE: BRIEF_TYPE_SAMPLE_VIDEO,
};

const ModalRequestOldSKU = ({
  opened,
  close,
  createRequest,
  loading,
  error,
}) => {
  const [skus, setSKUs] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [value, setValue] = useState("");
  const [chooseNumberNote, setChooseNumberNote] = useState(0);
  const [payloads, setPayloads] = useState([]);
  const [briefType, setBriefType] = useState(BRIEF_TYPES.NEW);
  // Handle changes in sample notes
  const handleSampleChange = (index, newContent) => {
    setPayloads((prevPayloads) =>
      prevPayloads.map((payload, i) =>
        i === index
          ? { ...payload, note: { ...payload.note, noteForVideo: newContent } }
          : payload
      )
    );
  };

  // Handle changes in video notes
  const handleVideoChange = (index, newContent) => {
    setPayloads((prevPayloads) =>
      prevPayloads.map((payload, i) =>
        i === index
          ? {
              ...payload,
              note: { ...payload.note, noteFromSampleForVideo: newContent },
            }
          : payload
      )
    );
  };
  // Update payloads when chooseNumberNote changes, preserving old content
  const handleNumberNoteChange = (event) => {
    let newNumberNote = toNumber(event.target.value) || 0;
    setChooseNumberNote(newNumberNote);
  };
  const generateNote = () => {
    setPayloads((prevPayloads) => {
      // If increased, add new empty objects
      if (chooseNumberNote > prevPayloads.length) {
        const additionalPayloads = map(
          new Array(chooseNumberNote - prevPayloads.length),
          () => ({
            sku: skus[0]?.sku,
            skuRef: skus[0]?.sku,
            videoThumbLink: skus[0]?.image,
            briefType,
            isRequestVideo: true,
            status: STATUS.OPTIMIZED_ADS_VIDEO,
            ...(selectedTeam && { rndTeam: selectedTeam }),
            note: { noteForVideo: "", noteFromSampleForVideo: "" },
            ...(value && {
              value: {
                rnd: value,
              },
            }),
          })
        );
        return [...prevPayloads, ...additionalPayloads];
      }
      // If decreased, remove the extra entries
      return prevPayloads.slice(0, chooseNumberNote);
    });
  };
  return (
    <Modal
      opened={opened}
      onClose={close}
      transitionProps={{ transition: "fade", duration: 200 }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius="md"
      size="xl"
      title={
        <Radio.Group
          name="favoriteFramework"
          label="Loại đề"
          styles={{
            root: {
              display: "flex",
              alignItems: "center",
              gap: 10,
            },
          }}
          withAsterisk
          value={briefType}
          onChange={(value) => setBriefType(value)}
        >
          <Group>
            <Radio value={BRIEF_TYPES.NEW} label="New" />
            <Radio value={BRIEF_TYPES.OPTMIZE} label="Optimize" />
          </Group>
        </Radio.Group>
      }
    >
      <SearchSKU setSKUs={setSKUs} />
      {!isEmpty(skus) && (
        <>
          <PreviewTable data={skus} setData={setSKUs} />
          <Divider />
          <Grid>
            <Grid.Col span={3}>
              <TextInput
                mt={5}
                label="Số lượng Sample/Video"
                value={chooseNumberNote}
                styles={{
                  label: {
                    marginBottom: 5,
                  },
                }}
                onChange={handleNumberNoteChange}
              />
            </Grid.Col>
            <Grid.Col span={3.5}>
              <Select
                mt={5}
                styles={{
                  label: {
                    marginBottom: 5,
                  },
                }}
                label="Select team"
                placeholder="Select team"
                data={BD_TEAMS}
                value={selectedTeam}
                onChange={(value) => setSelectedTeam(value)}
              />
            </Grid.Col>
            <Grid.Col span={3.5}>
              <Select
                mt={5}
                styles={{
                  label: {
                    marginBottom: 5,
                  },
                }}
                label="Select value"
                placeholder="Select value"
                data={["Small", "Medium", "Big", "Super Big"]}
                value={CONVERT_NUMBER_TO_STATUS[value]}
                onChange={(value) => {
                  setValue(CONVERT_STATUS_TO_NUMBER[value]);
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                mt="30px"
                loading={loading}
                disabled={isEmpty(skus) || chooseNumberNote === 0}
                onClick={async () => {
                  let refactoredPayloads = [];
                  // If there are payloads, use them, otherwise generate new ones
                  if (!isEmpty(payloads)) {
                    refactoredPayloads = map(payloads, (item) => ({
                      sku: skus[0]?.sku,
                      skuRef: skus[0]?.sku,
                      videoThumbLink: skus[0]?.image,
                      briefType,
                      isRequestVideo: true,
                      status: STATUS.OPTIMIZED_ADS_VIDEO,
                      ...(selectedTeam && { rndTeam: selectedTeam }),
                      ...(item?.note && {
                        note: item?.note,
                      }),
                      ...(value && {
                        value: {
                          rnd: value,
                        },
                      }),
                    }));
                  } else {
                    refactoredPayloads = map(
                      new Array(chooseNumberNote),
                      () => ({
                        sku: skus[0]?.sku,
                        skuRef: skus[0]?.sku,
                        videoThumbLink: skus[0]?.image,
                        briefType,
                        isRequestVideo: true,
                        status: STATUS.OPTIMIZED_ADS_VIDEO,
                        ...(selectedTeam && { rndTeam: selectedTeam }),
                        note: { noteForVideo: "", noteFromSampleForVideo: "" },
                        ...(value && {
                          value: {
                            rnd: value,
                          },
                        }),
                      })
                    );
                  }
                  await createRequest({
                    isOptimized: true,
                    payloads: refactoredPayloads,
                  });
                  if (!error) {
                    setSKUs([]);
                    setPayloads([]);
                    setChooseNumberNote(0);
                    close();
                  }
                }}
              >
                Request
              </Button>
            </Grid.Col>
            <Grid.Col span={3}>
              <Button color="red" onClick={generateNote}>
                Generate Note
              </Button>
            </Grid.Col>
          </Grid>
        </>
      )}
      <Divider my={15} />
      {!isEmpty(skus) &&
        map(payloads, (payload, index) => {
          return (
            <NoteRequest
              sampleValue={payload?.note?.noteForVideo}
              videoValue={payload?.note?.noteFromSampleForVideo}
              index={index + 1 + skus[0]?.numOfVideoRequested || 0}
              onChangeSampleValue={(content) =>
                handleSampleChange(index, content)
              }
              onChangeVideoValue={(content) =>
                handleVideoChange(index, content)
              }
            />
          );
        })}
    </Modal>
  );
};

export default ModalRequestOldSKU;
