import { ScrollArea, Select, TextInput } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { settingServices } from "../../../services/settings";
import { keys, map, toNumber, toUpper } from "lodash";

const AMZSetting = () => {
  const inputRef = useRef(null);

  const [elements, setElements] = useState({
    or: [
      {
        orders: {
          label: "Orders",
          value: 30,
          operator: "greater",
        },
        subtractDays: {
          label: "Subtract Days",
          value: 7,
          operator: "greater",
        },
      },
      {
        orders: {
          label: "Orders",
          value: 10,
          operator: "greater",
        },
        subtractDays: {
          label: "Subtract Days",
          value: 1,
          operator: "greater",
        },
      },
    ],
  });
  const [payload, setPayload] = useState({});
  const fetchDefaultPODSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier: "amz-dashboard-setting",
    });
    if (settingsResponse) {
      setPayload(settingsResponse?.data);
      setElements(settingsResponse?.data?.attribute?.optimizedMetric);
    }
  };
  const updateDefaultPODSetting = async ({ uid, payload }) => {
    await settingServices.updateSetting({
      uid,
      data: payload,
      view: "amz-dashboard-setting",
    });
  };
  useEffect(() => {
    fetchDefaultPODSetting();
  }, []);

  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
    minWidth: "150px",
  };
  const sections = ["or", "and"];
  return (
    <div
      style={{
        width: "auto",
        minWidth: "70%",
      }}
    >
      <ScrollArea scrollbars="x">
        <table
          style={{
            borderCollapse: "collapse",
            width: "auto",
            minWidth: "50%",
            overflow: "scroll",
            tableLayout: "auto",
          }}
        >
          <thead>
            <tr>
              <th style={headCellStyles}></th>
              <th style={headCellStyles}>Nhãn</th>
              <th style={headCellStyles}>Toán tử</th>
              <th style={headCellStyles}>Giá trị</th>
              <th style={headCellStyles}></th>
              <th style={headCellStyles}>Nhãn</th>
              <th style={headCellStyles}>Toán tử</th>
              <th style={headCellStyles}>Giá trị</th>
            </tr>
          </thead>
          <tbody>
            {sections?.map((section) => (
              <React.Fragment key={section}>
                {elements?.[section]?.map((subcategory, subIndex) => (
                  <tr key={subcategory}>
                    {/* Only render the section name once per group using rowSpan */}
                    {subIndex === 0 && (
                      <td
                        rowSpan={elements?.[section].length}
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "16px",
                        }}
                      >
                        {toUpper(section)}
                      </td>
                    )}
                    {map(keys(subcategory), (key, index) => (
                      <>
                        <td
                          key={subcategory?.[key]?.label || key}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <span>{subcategory?.[key]?.label || key}</span>
                        </td>
                        <td
                          key={`${subcategory?.[key]?.operator}-${index}`}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            <Select
                              styles={{
                                root: {
                                  width: "100px",
                                },
                              }}
                              data={[
                                { value: "greaterOrEqual", label: ">=" },
                                { value: "greater", label: ">" },
                                { value: "equal", label: "=" },
                                { value: "lessOrEqual", label: "<=" },
                                { value: "less", label: "<" },
                                { value: "notEqual", label: "≠" },
                              ]}
                              value={subcategory?.[key]?.operator}
                              onChange={(value) => {
                                const newElements = elements?.[section]?.map(
                                  (el, index) => {
                                    if (index === subIndex) {
                                      return {
                                        ...el,
                                        [key]: {
                                          ...el[key],
                                          operator: value,
                                        },
                                      };
                                    }
                                    return el;
                                  }
                                );
                                setElements({
                                  ...elements,
                                  [section]: newElements,
                                });
                                const newAttribute = {
                                  optimizedMetric: {
                                    ...elements,
                                    [section]: newElements,
                                  },
                                };
                                console.log(newAttribute);
                                updateDefaultPODSetting({
                                  uid: payload.uid,
                                  payload: {
                                    attribute: newAttribute,
                                  },
                                });
                              }}
                              readOnly={true}
                            />
                          </span>
                        </td>
                        <td
                          key={subcategory?.[key]?.value}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            <TextInput
                              key={`${section}-${subIndex}-${key}-${subcategory?.[key]?.value}`}
                              styles={{
                                root: {
                                  width: "100px",
                                },
                              }}
                              ref={inputRef}
                              value={subcategory?.[key]?.value}
                              onChange={(event) => {
                                const value = toNumber(event.target.value || 0);
                                const newElements = elements?.[section]?.map(
                                  (el, index) => {
                                    if (index === subIndex) {
                                      return {
                                        ...el,
                                        [key]: {
                                          ...el[key],
                                          value,
                                        },
                                      };
                                    }
                                    return el;
                                  }
                                );
                                setElements({
                                  ...elements,
                                  [section]: newElements,
                                });
                                const newAttribute = {
                                  optimizedMetric: {
                                    ...elements,
                                    [section]: newElements,
                                  },
                                };
                                updateDefaultPODSetting({
                                  uid: payload.uid,
                                  payload: {
                                    attribute: newAttribute,
                                  },
                                });
                              }}
                            />
                          </span>
                        </td>
                        {index !== keys(subcategory).length - 1 && (
                          <td
                            key={index}
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span>AND</span>
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </ScrollArea>
    </div>
  );
};

export default AMZSetting;
