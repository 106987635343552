import {
  Button,
  Divider,
  FileInput,
  Grid,
  Group,
  rem,
  TextInput,
} from "@mantine/core";
import Card from "../../components/Card";
import { IconPhotoCheck } from "@tabler/icons-react";
import { useState } from "react";
import { uploadServices } from "../../services/uploads";
import { useAuth0 } from "@auth0/auth0-react";

const Converter = () => {
  const iconImage = (
    <IconPhotoCheck style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );
  const { isAuthenticated } = useAuth0();
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const handleUploadImage = async () => {
    setLoading(true);
    const uploadResponse = await uploadServices.uploadUrl(imageUrl);
    if (uploadResponse) {
      setImage(uploadResponse?.data);
      console.log(uploadResponse);
    }
    setLoading(false);
  };
  const handleChangeImage = async (file) => {
    const uploadResponse = await uploadServices.upload(file);
    if (uploadResponse) {
      setImage(uploadResponse?.data);
      console.log(uploadResponse);
    }
    setValue(file);
  };
  const [value, setValue] = useState(null);
  return isAuthenticated ? (
    <Card title="Converter" classTitle="title-green">
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            styles={{
              label: {
                marginBottom: 5,
                fontWeight: "bold",
              },
            }}
            placeholder="Enter your Image URL"
            label="Enter your Image URL"
            rightSection={iconImage}
            rightSectionPointerEvents="none"
            mt="md"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <FileInput
            styles={{
              label: {
                marginBottom: 5,
                fontWeight: "bold",
              },
            }}
            rightSection={iconImage}
            label="Attach your Image"
            placeholder="Your Image"
            rightSectionPointerEvents="none"
            mt="md"
            accept="image/png,image/jpeg"
            value={value}
            onChange={handleChangeImage}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Group justify="center">
            <Button
              loading={loading}
              onClick={handleUploadImage}
              disabled={!imageUrl}
            >
              Upload
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider margins="md" />
        </Grid.Col>
        <Grid.Col span={12}>
          <span>New URL: </span>
          <div>{image?.shortUrl}</div>
        </Grid.Col>
      </Grid>
    </Card>
  ) : (
    <div>Unauthorized</div>
  );
};

export default Converter;
