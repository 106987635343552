import { Modal, ScrollArea, Table, Text } from "@mantine/core";
import { groupBy, isEmpty, keys, map, toUpper } from "lodash";
import { useEffect, useState } from "react";

const ModalCampaignError = ({ opened, close, adsLinks }) => {
  const [data, setData] = useState(adsLinks);
  useEffect(() => {
    if (!isEmpty(adsLinks)) {
      const groupByCampaignNames = groupBy(adsLinks, "campaignName");
      const campaignNames = keys(groupByCampaignNames);
      const transformedData = map(campaignNames, (campaignName) => {
        const campaignInfos = groupByCampaignNames[campaignName];
        return {
          campaignName: campaignName,
          status: campaignInfos[0]?.campaignStatus,
          error: campaignInfos[0]?.errorMessage || "",
        };
      });
      setData(transformedData);
    }
  }, [adsLinks]);
  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };
  const rows = map(data, (item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td
          style={{
            textAlign: "center",
          }}
        >
          <Text fw={700}>{item?.campaignName}</Text>
        </Table.Td>
        <Table.Td
          style={{
            textAlign: "center",
          }}
        >
          <Text fw={700}>{toUpper(item?.status)}</Text>
        </Table.Td>
        <Table.Td
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bold",
          }}
        >
          <Text>{item?.error}</Text>
        </Table.Td>
      </Table.Tr>
    );
  });
  return (
    <Modal
      opened={opened}
      onClose={close}
      transitionProps={{ transition: "fade", duration: 200 }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      radius="md"
      size="1000px"
    >
      {!isEmpty(data) ? (
        <ScrollArea>
          <Table miw={500} verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={headCellStyles}>Campaign Name</Table.Th>
                <Table.Th style={headCellStyles}>Status</Table.Th>
                <Table.Th style={headCellStyles}>Error</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </ScrollArea>
      ) : (
        <Text>No data</Text>
      )}
    </Modal>
  );
};

export default ModalCampaignError;
