import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  ceil,
  concat,
  findIndex,
  groupBy,
  isEqual,
  keys,
  map,
  remove,
  sortBy,
  filter,
  isEmpty,
  toNumber,
  includes,
  reduce,
  sumBy,
  mean,
  round,
  last,
  meanBy,
} from "lodash";
import { CREATE_CAMP_FLOWS, CREATE_CAMPAIGN_STATUS } from "./constant";
import axios from "axios";
import { ObjectParam, StringParam } from "use-query-params";
import moment from "moment-timezone";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const delayTime = (number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, number);
  });
};

export const getEditorStateAsString = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  return draftToHtml(rawContentState);
};

// Convert string to editor state
export const getStringAsEditorState = (htmlString) => {
  if (!htmlString || typeof htmlString !== "string") {
    // Return an empty editor state if the input is invalid
    return EditorState.createEmpty();
  }

  const contentBlock = htmlToDraft(htmlString);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }

  // Return an empty editor state if the content block is invalid
  return EditorState.createEmpty();
};

export const CONVERT_STATUS_TO_NUMBER = {
  Small: 1,
  Medium: 2,
  Big: 3,
  "Super Big": 4,
  "Small+": 1.5,
};
export const arraysMatchExact = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((element, index) => element === arr2[index]);
};
export const arraysMatchUnordered = (arr1, arr2) => {
  return isEqual(sortBy(arr1), sortBy(arr2));
};
export const CONVERT_NUMBER_TO_STATUS = {
  1: "Small",
  2: "Medium",
  3: "Big",
  4: "Super Big",
  1.5: "Small+",
};
export const SIZES = {
  Small: 1,
  Medium: 2,
  Big: 3,
};
export const VALUES = {
  Small: 1,
  Medium: 2,
  Big: 3,
  "Super Big": 4,
  "Small+": 1.5,
};

export const CONVERT_BRIEF_TYPE_TO_OBJECT_NAME = {
  "Scale - Product Line": "productLine",
  "Scale - Clipart": "clipart",
  "Scale - Niche": "clipart",
  "New - Phủ Market": "clipart",
  "Scale - Design": "skuInfo",
  "New - Mix Match": "productLine",
  "Optimize Scale PL": "productLine",
};
export const generateDescendingArray = (n) => {
  return Array.from({ length: 4 }, (_, i) => n - i);
};
export const generateAscendingArray = (n) => {
  return Array.from({ length: 4 }, (_, i) => n + i);
};
export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function reorderArrayById(items, id) {
  // Find the index of the item with the specified id
  const index = findIndex(items, { uid: id });

  if (index !== -1) {
    // Remove the item from its original position
    const [item] = remove(items, (item, i) => i === index);
    // Concatenate the item at the beginning of the array
    items = concat([item], items);
  }

  return items;
}
export function toCamelCase(str) {
  return str
    .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
    .replace(/^[A-Z]/, (match) => match.toLowerCase());
}
export function toPascalCase(str) {
  return str
    ?.replace(/[-_\s]+(.)?/g, (_, c) => (c ? c?.toUpperCase() : ""))
    ?.replace(/^[a-z]/, (match) => match?.toUpperCase());
}

export async function checkLinkType(url, type) {
  try {
    // Send a HEAD request to fetch the headers
    const response = await axios.head(url);

    // Check the Content-Type header
    const contentType = response.headers["content-type"];

    switch (type) {
      case "image":
        return contentType.startsWith("image/");
      case "video":
        return contentType.startsWith("video/");
      default:
        return false;
    }
  } catch (error) {
    return false;
  }
}

export const transformedCampaignsPayloads = (payload) => {
  const ads = filter(
    payload.ads,
    (ad) =>
      (ad.postId &&
        ad.pageId &&
        !ad.campaignId &&
        !ad?.campaignName &&
        ad?.campaignStatus !== CREATE_CAMPAIGN_STATUS.PROCESSING) ||
      ad?.campaignStatus === CREATE_CAMPAIGN_STATUS.FAILED
  );
  if (isEmpty(ads)) return [];
  switch (payload?.runFlow) {
    case CREATE_CAMP_FLOWS[0].title: {
      return [
        {
          briefId: payload?.briefId,
          rootCampId: payload?.rootCampaign?.campaignId,
          campInfo: {
            dailyBudget: payload?.budget,
            name: `${payload.team} - ${payload.sku} - ${payload.batch} - Test${
              payload.exCampIds.length + 1
            }`,
          },
          adsInfo: map(ads, (ad) => {
            return {
              adsLinkId: ad.uid,
              name: ad.postName,
              objectStoryId: `${ad.pageId}_${ad.postId}`,
            };
          }),
        },
      ];
    }
    case CREATE_CAMP_FLOWS[1].title: {
      const groupedAds = groupBy(ads, "type");
      const keyTypes = keys(groupedAds);
      const budgetPerCamp = ceil(payload?.budget / keyTypes.length);
      return map(keyTypes, (type, index) => {
        const ads = groupedAds[type];
        return {
          briefId: payload?.briefId,
          rootCampId: payload?.rootCampaign?.campaignId,
          campInfo: {
            dailyBudget: budgetPerCamp,
            name: `${payload.team} - ${payload.sku} - ${payload.batch} - Test${
              payload.exCampIds.length + index + 1
            }`,
          },
          adsInfo: map(ads, (ad) => {
            return {
              adsLinkId: ad.uid,
              name: ad.postName,
              objectStoryId: `${ad.pageId}_${ad.postId}`,
            };
          }),
        };
      });
    }
    case CREATE_CAMP_FLOWS[2].title: {
      const budgetPerCamp = ceil(payload?.budget / ads.length);
      return map(ads, (ad, index) => {
        return {
          briefId: payload?.briefId,
          rootCampId: payload?.rootCampaign?.campaignId,
          campInfo: {
            dailyBudget: budgetPerCamp,
            name: `${payload.team} - ${payload.sku} - ${payload.batch} - Test${
              payload.exCampIds.length + index + 1
            }`,
          },
          adsInfo: [
            {
              adsLinkId: ad.uid,
              name: ad.postName,
              objectStoryId: `${ad.pageId}_${ad.postId}`,
            },
          ],
        };
      });
    }
    default: {
      return [];
    }
  }
};

export const transformedSorting = (sorting) => {
  // for only case id is time
  if (sorting[0].id === "time") {
    return {
      createdAt: sorting[0].desc ? "asc" : "desc",
    };
  }
  if (sorting[0].id === "timeVideoCreated") {
    return {
      videoRequestedDate: sorting[0].desc ? "asc" : "desc",
    };
  }

  return {
    [sorting[0].id]: sorting[0].desc ? "desc" : "asc",
  };
};

// Custom Date Array Param to handle arrays of date strings
export const DateRangeParam = {
  encode: (dates) => {
    return dates ? dates.map((date) => new Date(date)) : undefined;
  },
  decode: (dates) => {
    return dates ? map(dates, (date) => new Date(date)) : null;
  },
};

// custom status param
export const StatusParam = {
  encode: (status) => {
    return status ? map(status, (x) => toNumber(x)) : undefined;
  },
  decode: (status) => {
    return status ? map(status, (x) => toNumber(x)) : null;
  },
};

// custom status value param
export const StatusValueParam = {
  encode: (statusValue) => {
    if (statusValue === null) return "all";
    return statusValue ? statusValue : null;
  },
  decode: (statusValue) => {
    if (!statusValue) return "Undone";
    if (statusValue === "all") return null;
    return statusValue ? statusValue : null;
  },
};
// Custom parameter type to handle null values
export const NullableStringParam = {
  encode: (value) => {
    return !value ? null : StringParam.encode(value);
  },
  decode: (value) => {
    return !value ? null : StringParam.decode(value);
  },
};

export const SizeParam = {
  encode: (size) => {
    return size ? ObjectParam.encode(size) : null;
  },
  decode: (size) => {
    return size ? toNumber(size) : null;
  },
};

export const CURRENT_MONTH = moment().month() + 1;
export const CURRENT_YEAR = moment().year();
export const CURRENT_DATE = moment().date();
export const getMonthArray = () => {
  const currentMonth = moment();
  const previousMonth = moment().subtract(1, "months");
  const nextMonth = moment().add(1, "months");

  return [
    previousMonth.month() + 1,
    currentMonth.month() + 1,
    nextMonth.month() + 1,
  ];
};
export const MKT_LAUNCHING_TYPES = ["LAUNCHING", "OPTIMIZE", "SCALE"];
export const getKeyHasValue = ({ query, specialKeys }) => {
  const queryKeys = keys(query);
  const transformedQuery = filter(queryKeys, (key) => query[key])?.map(
    (key) => {
      if (includes(specialKeys, key)) {
        return {
          [key]: map(query[key], "value"),
        };
      }
      return {
        [key]: query[key],
      };
    }
  );

  return reduce(
    transformedQuery,
    (acc, curr) => {
      return { ...acc, ...curr };
    },
    {}
  );
};

// Function to calculate summary data
export const calculateSummaryRowReport = (data, currentMonth) => {
  return {
    formattedDate: `Total ${moment()
      .month(currentMonth - 1)
      .format("MMM")}`,
    totalBriefs: sumBy(data, "totalBriefs")?.toLocaleString(),
    totalDeletedBriefs: sumBy(data, "totalDeletedBriefs")?.toLocaleString(),
    expectedQuota: sumBy(data, "expectedQuota")?.toLocaleString(),
    actualQuota: sumBy(data, "actualQuota")?.toLocaleString(),
    balance: sumBy(data, "balance")?.toLocaleString(),
    daysToDone: sumBy(data, "daysToDone")?.toLocaleString(),
    totalArchivedBriefs: sumBy(data, "totalArchivedBriefs")?.toLocaleString(),
  };
};
export const calculateSummaryRowCommonReport = (
  data,
  currentMonth,
  accessorKey,
  accessorKeySkuDone
) => {
  return {
    formattedDate: `Total ${moment()
      .month(currentMonth - 1)
      .format("MMM")}`,
    totalBriefs: sumBy(data, "totalBriefs")?.toLocaleString(),
    timeAverageToDone: !isNaN(mean(map(data, "timeAverageToDone")))
      ? mean(map(data, "timeAverageToDone"))?.toLocaleString()
      : 0,
    timeRealToDone: sumBy(data, "timeRealToDone")?.toLocaleString(),
    balanceYesterday: sumBy(data, "balanceYesterday")?.toLocaleString(),
    [accessorKey]: sumBy(data, accessorKey)?.toLocaleString(),
    ...(accessorKeySkuDone && {
      [accessorKeySkuDone]: sumBy(data, accessorKeySkuDone)?.toLocaleString(),
    }),
    totalDeletedBriefs: sumBy(data, "totalDeletedBriefs")?.toLocaleString(),
    balance: sumBy(data, "balance")?.toLocaleString(),
    daysToDone: sumBy(data, "daysToDone")?.toLocaleString(),
    totalArchivedBriefs: sumBy(data, "totalArchivedBriefs")?.toLocaleString(),
    totalTimeToDoneSource: sumBy(
      data,
      "totalTimeToDoneSource"
    )?.toLocaleString(),
    totalTimeToDoneEdit: sumBy(data, "totalTimeToDoneEdit")?.toLocaleString(),
  };
};
export const calculateSummaryRowMKTReport = (data, currentMonth) => {
  return {
    formattedDate: `Total`,
    totalOrders: sumBy(data, "totalOrders")?.toLocaleString(),
    totalTimeToDoneToday: sumBy(data, "totalTimeToDoneToday")?.toLocaleString(),
    totalBudgets: sumBy(data, "totalBudgets")?.toLocaleString(),
    totalAdsRev: sumBy(data, "totalAdsRev")?.toLocaleString(),
    totalUndoneToday: sumBy(data, "totalUndoneToday")?.toLocaleString(),
    totalSkuDone: sumBy(data, "totalSkuDone")?.toLocaleString(),
    balanceYesterday: sumBy(data, "balanceYesterday")?.toLocaleString(),
    totalPosts: sumBy(data, "totalPosts")?.toLocaleString(),
    totalPostsDeleted: sumBy(data, "totalPostsDeleted")?.toLocaleString(),
    balance: sumBy(data, "balance")?.toLocaleString(),
    totalTimeToDonePostsToday: sumBy(
      data,
      "totalTimeToDonePostsToday"
    )?.toLocaleString(),
    isSummaryRow: true,
    // new fields
    totalInputCards: sumBy(data, "totalInputCards")?.toLocaleString(),
    averageTimeDone: round(
      meanBy(data, "averageTimeDone") || 0,
      2
    )?.toLocaleString(),
    averageTimeRemaining: round(
      meanBy(data, "averageTimeRemaining") || 0,
      2
    )?.toLocaleString(),
    totalRemainingCards: last(data)?.totalRemainingCards?.toLocaleString(),
    daysToDone: round(meanBy(data, "daysToDone") || 0, 0)?.toLocaleString(),
    totalNewOptimize: sumBy(data, "totalNewOptimize")?.toLocaleString(),
    totalOldOptimize: sumBy(data, "totalOldOptimize")?.toLocaleString(),
    totalDoneOptimize: sumBy(data, "totalDoneOptimize")?.toLocaleString(),
    totalFutureHero: sumBy(data, "totalFutureHero")?.toLocaleString(),
    totalFutureHeroRate: sumBy(data, "totalFutureHeroRate")?.toLocaleString(),
    totalHero: sumBy(data, "totalHero")?.toLocaleString(),
    totalOptimizeRate: round(
      meanBy(data, "totalOptimizeRate") || 0,
      1
    )?.toLocaleString(),
    totalRemainingOptimize:
      last(data)?.totalRemainingOptimize?.toLocaleString(),
  };
};
export const calculateSummaryRowMasterReport = (data, currentMonth) => {
  return {
    formattedDate: `Total ${moment()
      .month(currentMonth - 1)
      .format("MMM")}`,
    rndDoneCard: sumBy(data, "rndDoneCard")?.toLocaleString(),
    designDoneCard: sumBy(data, "designDoneCard")?.toLocaleString(),
    epmDoneCard: sumBy(data, "epmDoneCard")?.toLocaleString(),
    sampleDoneCard: sumBy(data, "sampleDoneCard")?.toLocaleString(),
    videoDoneCard: sumBy(data, "videoDoneCard")?.toLocaleString(),
    totalBudget: sumBy(data, "totalBudget")?.toLocaleString(),
    totalCampDone: sumBy(data, "totalCampDone")?.toLocaleString(),
    totalSkuLaunch: sumBy(data, "totalSkuLaunch")?.toLocaleString(),
    totalLaunchOptimize: sumBy(data, "totalLaunchOptimize")?.toLocaleString(),
    totalDoneOptimize: sumBy(data, "totalDoneOptimize")?.toLocaleString(),
    totalScale: sumBy(data, "totalScale")?.toLocaleString(),
    totalDesignBalance: sumBy(data, "totalDesignBalance")?.toLocaleString(),
    totalEpmBalance: sumBy(data, "totalEpmBalance")?.toLocaleString(),
    totalSampleBalance: sumBy(data, "totalSampleBalance")?.toLocaleString(),
    totalVideoBalance: sumBy(data, "totalVideoBalance")?.toLocaleString(),
    totalMktBalance: sumBy(data, "totalMktBalance")?.toLocaleString(),
    totalSkuDone: sumBy(data, "totalSkuDone")?.toLocaleString(),
    optimize: {
      totalDoneOptimize: sumBy(
        data,
        (x) => x.optimize.totalDoneOptimize
      )?.toLocaleString(),
      averageTimeDone: sumBy(
        data,
        (x) => x.optimize.averageTimeDone
      )?.toLocaleString(),
    },
    design: {
      balance: sumBy(data, (x) => x.design.balance)?.toLocaleString(),
      daysToDone: sumBy(data, (x) => x.design.daysToDone)?.toLocaleString(),
    },
    epm: {
      balance: sumBy(data, (x) => x.epm.balance)?.toLocaleString(),
      daysToDone: sumBy(data, (x) => x.epm.daysToDone)?.toLocaleString(),
    },
    sample: {
      balance: sumBy(data, (x) => x.sample.balance)?.toLocaleString(),
    },
    video: {
      balance: sumBy(data, (x) => x.video.balance)?.toLocaleString(),
      totalTimeToDoneEdit: sumBy(data, (x) => x.video.totalTimeToDoneEdit)
        ?.toFixed(1)
        ?.toLocaleString(),
    },
  };
};

export const getComparisonColor = (
  keyName,
  currentValue,
  elements,
  originalData
) => {
  // Find the element with the matching metric
  const element = elements?.find((el) => el.metric === keyName);

  if (!element || originalData?.isSummaryRow === true) {
    return "";
  }

  // Helper function to compare values
  const compare = (currentValue, compareValue, operator) => {
    if (isNaN(compareValue) || compareValue === "") {
      return "";
    }
    switch (operator) {
      case "greater":
        return currentValue > compareValue;
      case "less":
        return currentValue < compareValue;
      case "greaterOrEqual":
        return currentValue >= compareValue;
      case "lessOrEqual":
        return currentValue <= compareValue;
      case "equal":
        return currentValue === compareValue;
      default:
        return "";
    }
  };

  // Compare with goodValue
  if (compare(currentValue, element.goodValue, element.goodOperator)) {
    return "green";
  }

  // Compare with warningValue
  if (compare(currentValue, element.warningValue, element.warningOperator)) {
    return "red";
  }

  // Return default color if no conditions are met
  return "";
};

export const getComparisonColorV2 = ({
  currentValue,
  element,
  originalData,
}) => {
  if (!element || originalData?.isSummaryRow === true) {
    return "";
  }

  // Helper function to compare values
  const compare = (currentValue, compareValue, operator) => {
    if (isNaN(compareValue) || compareValue === "") {
      return "";
    }
    switch (operator) {
      case "greater":
        return currentValue > compareValue;
      case "less":
        return currentValue < compareValue;
      case "greaterOrEqual":
        return currentValue >= compareValue;
      case "lessOrEqual":
        return currentValue <= compareValue;
      case "equal":
        return currentValue === compareValue;
      default:
        return "";
    }
  };

  // Compare with goodValue
  if (compare(currentValue, element.goodValue, element.goodOperator)) {
    return "green";
  }

  // Compare with warningValue
  if (compare(currentValue, element.warningValue, element.warningOperator)) {
    return "red";
  }

  // Return default color if no conditions are met
  return "";
};
// Check if a given date is Sunday
export const isSunday = (date) => {
  return moment(date, "MMM DD").day() === 0; // Sunday is represented by 0 in moment
};
