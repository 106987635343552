import { TagsInput } from "@mantine/core";
import { useEffect, useRef } from "react";
import { showNotification } from "../../utils/index";
import { isEmpty, uniq } from "lodash";

const EditMultipleLink = ({
  value,
  onChange,
  onBlurError,
  onBlurSuccess,
  readOnly,
  adsLinks,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (!inputRef) return;
    inputRef.current.focus();
  }, []);

  return (
    <TagsInput
      ref={inputRef}
      value={value}
      splitChars={[",", " ", "|", "\r\n", "\n"]}
      clearable
      withScrollArea={true}
      styles={{
        input: {
          maxWidth: "300px",
        },
      }}
      maxDropdownHeight="100px"
      onChange={(value) => {
        onChange?.(value);
        // Regular expressions for video and image file extensions
        const createdVideoLinks = adsLinks
          .filter((link) => link?.type === "video" && link?.postId)
          .map((link) => link.value);
        const allValidLinks = uniq([...value, ...createdVideoLinks]);
        if (!isEmpty(createdVideoLinks)) {
          showNotification(
            "Thành công",
            `${createdVideoLinks.length}/${allValidLinks.length} đã tạo Post nên không thể xóa hay update`,
            "yellow"
          );
        }
        onBlurSuccess?.(allValidLinks);
      }}
      readOnly={readOnly}
    />
  );
};

export default EditMultipleLink;
