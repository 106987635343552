import { Select, Table, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { settingServices } from "../../../services/settings";
import { keys, map, toNumber } from "lodash";

const VideoSampleSetting = () => {
  const [elements, setElements] = useState([]);
  const [payload, setPayload] = useState({});
  const fetchDefaultSampleSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier: "video-sample",
    });
    if (settingsResponse) {
      setPayload(settingsResponse?.data);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute?.delaySource),
        (key) => {
          return {
            metric: key,
            operator:
              settingsResponse?.data?.attribute?.delaySource?.[key].operator,
            value: settingsResponse?.data?.attribute?.delaySource?.[key].value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  const updateDefaultSampleSetting = async ({ uid, payload }) => {
    await settingServices.updateSetting({
      uid,
      data: payload,
      view: "video-sample",
    });
  };
  useEffect(() => {
    fetchDefaultSampleSetting();
  }, []);
  const rows = map(elements, (element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.metric}</Table.Td>
      <Table.Td>
        <Select
          styles={{
            root: {
              width: "100px",
            },
          }}
          data={[
            { value: "greaterOrEqual", label: ">=" },
            { value: "greater", label: ">" },
            { value: "equal", label: "=" },
            { value: "lessOrEqual", label: "<=" },
            { value: "less", label: "<" },
            { value: "notEqual", label: "≠" },
          ]}
          value={element.operator}
          readOnly={true}
          onChange={(value) => {
            const key = element?.metric;
            const newElements = elements.map((el) => {
              if (el.metric === key) {
                return {
                  ...el,
                  operator: value,
                };
              }
              return el;
            });
            setElements(newElements);
            const newAttribute = {
              ...payload?.attribute,
              delaySource: {
                ...payload?.attribute.delaySource,
                [key]: {
                  ...payload?.attribute.delaySource?.[key],
                  operator: value,
                },
              },
            };
            updateDefaultSampleSetting({
              uid: payload.uid,
              payload: {
                attribute: newAttribute,
              },
            });
          }}
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          styles={{
            root: {
              width: "100px",
            },
          }}
          value={element?.value}
          onChange={(event) => {
            const value = event.target.value;
            const key = element?.metric;
            const newElements = elements.map((el) => {
              if (el.metric === key) {
                return {
                  ...el,
                  value,
                };
              }
              return el;
            });
            setElements(newElements);
            const newAttribute = {
              ...payload?.attribute,
              delaySource: {
                ...payload?.attribute.delaySource,
                [key]: {
                  ...payload?.attribute.delaySource?.[key],
                  value: value ? toNumber(value) : 0,
                },
              },
            };
            updateDefaultSampleSetting({
              uid: payload.uid,
              payload: {
                attribute: newAttribute,
              },
            });
          }}
        />
      </Table.Td>
    </Table.Tr>
  ));

  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  return (
    <div
      style={{
        width: "50%",
      }}
    >
      <Table
        stickyHeader
        stickyHeaderOffset={60}
        mt="md"
        horizontalSpacing="xl"
        __size="md"
        borderColor="gray"
        striped="even"
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>Chỉ số</Table.Th>
            <Table.Th style={headCellStyles}>Điều kiện</Table.Th>
            <Table.Th style={headCellStyles}>Giá trị</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

export default VideoSampleSetting;
