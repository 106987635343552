import { ActionIcon, Tooltip as MantineTooltip } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IconDeviceFloppy } from "@tabler/icons-react";
import cn from "classnames";
import styles from "./Editor.module.sass";
import "./Editor.css";
import { generateRandomString } from "../../utils";
import { uploadServices } from "../../services/uploads";

const QuillEditor = ({
  state,
  onChange,
  readOnly = false,
  label,
  onClick,
  button,
  loading,
  minHeight,
  maxHeight,
  isExpand = true,
}) => {
  const reactQuillRef = useRef(null);
  const handleImageUpload = async (file) => {
    try {
      const fileName = generateRandomString(30);
      const updateFileResponse = await uploadServices.upload(file, fileName);
      if (updateFileResponse) {
        return updateFileResponse.data.url;
      }
      return "";
    } catch (error) {
      console.log("Error uploading image", error);
      return "";
    }
  };

  useEffect(() => {
    const quill = reactQuillRef.current.getEditor();
    const handlePaste = async (event) => {
      try {
        const clipboardData = event?.clipboardData || window?.clipboardData;
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items?.[i]?.type.indexOf("image") !== -1) {
            event.preventDefault();
            const file = items?.[i]?.getAsFile();
            const url = await handleImageUpload(file);
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", url);
          }
        }
      } catch (error) {
        console.log("Error at quill paste event", error);
      }
    };
    quill?.root?.addEventListener("paste", handlePaste);
    // Cleanup function to remove the event listener
    return () => {
      quill?.root?.removeEventListener("paste", handlePaste);
    };
  }, []);
  return (
    <div className={cn(styles.editor, { [styles.editorButton]: button })}>
      {label && <div>{label}</div>}
      <ReactQuill
        theme="snow"
        value={state}
        onChange={onChange}
        readOnly={readOnly}
        style={{
          minHeight: minHeight || "500px",
          maxHeight: maxHeight || "500px",
          overflowY: "scroll",
          overflowx: "hidden",
        }}
        className={isExpand ? "ql-editor" : false}
        ref={reactQuillRef}
      />
      {button && (
        <MantineTooltip label="Save">
          <ActionIcon
            variant="filled"
            aria-label="Settings"
            style={{
              position: "absolute",
              top: "30px",
              right: "20px",
            }}
            onClick={onClick}
            loading={loading || false}
          >
            <IconDeviceFloppy
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </MantineTooltip>
      )}
    </div>
  );
};

export default QuillEditor;
