import { Flex, Grid } from "@mantine/core";

const CardInformation = ({
  totalVideoCardsAMZ,
  totalVideoCardsBD1,
  totalVideoCardsBD2,
  totalVideoCardsBD3,
  totalVideoCardsUnknown,
  totalVideoCards,
  timeToDoneEdit,
  timeToDoneSourceNotUID,
}) => {
  return (
    <Grid
      style={{
        padding: "10px",
        display: "flex",
        justifyContent: "start",
      }}
    >
      <Grid.Col
        span={12}
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#EFF0F1",
          marginBottom: "10px",
        }}
      >
        <Grid>
          <Grid.Col span={8}>
            {" "}
            <Flex
              style={{
                gap: "30px",
              }}
              justify="start"
              align="center"
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Số Card: {totalVideoCards || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                BD1: {totalVideoCardsBD1 || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                BD2:{totalVideoCardsBD2 || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                BD3: {totalVideoCardsBD3 || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                AMZ: {totalVideoCardsAMZ || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Unknown: {totalVideoCardsUnknown || 0}
              </div>
            </Flex>
          </Grid.Col>
          <Grid.Col span={4}>
            <Flex justify="end" gap={10}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Done Quay: {timeToDoneSourceNotUID || 0}{" "}
                {timeToDoneSourceNotUID > 1 ? "days" : "day"}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Done Edit: {timeToDoneEdit || 0}
                {timeToDoneEdit > 1 ? " days" : " day"}
              </div>
            </Flex>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};

export default CardInformation;
