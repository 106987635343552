import React from "react";
import useTable from "./useTable";
import { Group, Radio, ScrollArea, SegmentedControl } from "@mantine/core";
import AppMultiSelect from "../../../components/CustomMultiselect";
import {
  CURRENT_MONTH,
  getComparisonColor,
  getMonthArray,
  isSunday,
  toPascalCase,
} from "../../../utils";
import { find, includes, map } from "lodash";

const headCellStyles = {
  fontWeight: "bold",
  color: "#000000",
  backgroundColor: "#f2f3f5",
  height: "90px",
  minWidth: "80px",
  borderRight: "1px solid #d1d6e3",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "8px",
};
const sections = ["launching", "optimized", "scale"];
const subcategories = {
  launching: [
    {
      name: "totalSkuLaunch",
      label: "SKU Launching",
      compareLabelName: "totalSkuLaunch",
    },
    {
      name: "skuLaunchAccumulate",
      label: "Tích lũy SKU",
      compareLabelName: "skuLaunchAccumulate",
    },
    {
      name: "totalOrders",
      label: "Order",
      compareLabelName: "totalLaunchingOrders",
    },
    {
      name: "adsRev",
      label: "Ads/Rev (%)",
      compareLabelName: "totalLaunchingAdsRev",
    },
    {
      name: "optimizeRate",
      label: "Optimize Rate (%)",
      compareLabelName: "totalLaunchingOptimizeRate",
    },
  ],
  optimized: [
    {
      name: "totalSkuOptimize",
      label: "SKU Optimize",
      compareLabelName: "totalSkuOptimize",
    },
    {
      name: "skuOptimizeAccumulate",
      label: "Tích lũy SKU",
      compareLabelName: "skuOptimizeAccumulate",
    },
    {
      name: "totalOrders",
      label: "Order",
      compareLabelName: "totalOptimizedOrders",
    },
    {
      name: "adsRev",
      label: "Ads/Rev (%)",
      compareLabelName: "totalOptimizedAdsRev",
    },
    {
      name: "futureHeroRate",
      label: "Future Hero Rate (%)",
      compareLabelName: "futureHeroRate",
    },
  ],
  scale: [
    {
      name: "totalFutureHero",
      label: "SKU Future Hero",
      compareLabelName: "totalFutureHero",
    },
    {
      name: "skuScaleAccumulate",
      label: "Tích lũy SKU",
      compareLabelName: "skuScaleAccumulate",
    },
    {
      name: "totalOrders",
      label: "Order",
      compareLabelName: "totalScaleOrders",
    },
    {
      name: "adsRev",
      label: "Ads/Rev (%)",
      compareLabelName: "totalScaleAdsRev",
    },
    {
      name: "heroRate",
      label: "Hero Rate (%)",
      compareLabelName: "heroRate",
    },
    {
      name: "totalHero",
      label: "SKU Hero",
      compareLabelName: "totalHero",
    },
  ],
};

const DATES = {
  THIRTY_DAYS: {
    LABEL: "30 days",
    VALUE: 31,
  },
  SEVEN_DAYS: {
    LABEL: "7 days",
    VALUE: 7,
  },
  THREE_DAYS: {
    LABEL: "3 days",
    VALUE: 3,
  },
};
// Component to render the table
const ResultTableV2 = ({ query, setQuery, endPoint, elements }) => {
  const {
    data,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  } = useTable({
    query,
    setQuery,
    endPoint,
  });

  const uniqueDates = map(data, (item) => item?.key);

  // Function to get values for each row
  const getValuesForRow = (section, subcategory) => {
    return map(uniqueDates, (date) => {
      const sectionData = find(data, (item) => item?.key === date)?.[section];
      return {
        value: sectionData?.[subcategory],
        date,
      }; // Default to empty if no value
    });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 5px",
          gap: "50px",
          flexWrap: "wrap-reverse",
        }}
      >
        <Group>
          <Radio.Group value={query?.days} onChange={handleChangeDateRange}>
            <Group>
              <Radio
                value={DATES.THIRTY_DAYS.VALUE}
                label={DATES.THIRTY_DAYS.LABEL}
              />
              <Radio
                value={DATES.SEVEN_DAYS.VALUE}
                label={DATES.SEVEN_DAYS.LABEL}
                disabled={query?.month !== CURRENT_MONTH}
              />

              <Radio
                value={DATES.THREE_DAYS.VALUE}
                label={DATES.THREE_DAYS.LABEL}
                disabled={query?.month !== CURRENT_MONTH}
              />
            </Group>
          </Radio.Group>
          <AppMultiSelect
            placeholder={"Select team"}
            defaultValue={query?.teams}
            options={[
              { label: "BD1", value: "BD1" },
              {
                label: "BD2",
                value: "BD2",
              },
              { label: "BD3", value: "BD3" },
              { label: "AMZ", value: "AMZ" },
            ]}
            onChange={(value) => {
              handleChangeTeams(value);
            }}
            onClear={() => {
              onClearTeams();
            }}
          />
        </Group>
        <Group>
          <SegmentedControl
            value={query?.month?.toString()}
            onChange={handleChangeMonth}
            data={getMonthArray().map((x) => {
              return {
                value: `${x}`,
                label: `T${x}`,
              };
            })}
          />
        </Group>
      </div>
      <ScrollArea scrollbars="x">
        <table
          style={{
            borderCollapse: "collapse",
            width: "auto",
            minWidth: "50%",
            overflow: "scroll",
            tableLayout: "auto",
          }}
        >
          <thead>
            <tr>
              <th style={headCellStyles}></th>
              <th style={headCellStyles}></th>
              {uniqueDates?.map((date) => (
                <th key={date} style={headCellStyles}>
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sections.map((section) => (
              <React.Fragment key={section}>
                {subcategories?.[section]?.map((subcategory, subIndex) => (
                  <tr key={subcategory}>
                    {/* Only render the section name once per group using rowSpan */}
                    {subIndex === 0 && (
                      <td
                        rowSpan={subcategories?.[section].length}
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "16px",
                        }}
                      >
                        {toPascalCase(section)}
                      </td>
                    )}
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        minWidth: "170px",
                        ...(includes(
                          [
                            "skuLaunchAccumulate",
                            "skuOptimizeAccumulate",
                            "skuScaleAccumulate",
                          ],
                          subcategory?.name
                        ) && {
                          backgroundColor: "#fefff0",
                        }),
                      }}
                    >
                      {subcategory?.label}
                    </td>
                    {getValuesForRow(section, subcategory?.name)?.map(
                      ({ value, date }, index) => (
                        <td
                          key={index}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            backgroundColor: isSunday(date) ? "#f2f3f5" : "",
                            ...(includes(
                              [
                                "skuLaunchAccumulate",
                                "skuOptimizeAccumulate",
                                "skuScaleAccumulate",
                              ],
                              subcategory?.name
                            ) && {
                              backgroundColor: "#fefff0",
                            }),
                          }}
                        >
                          <span
                            style={{
                              color: getComparisonColor(
                                subcategory.compareLabelName,
                                value,
                                elements
                              ),
                            }}
                          >
                            {value}
                          </span>
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </ScrollArea>
    </div>
  );
};

export default ResultTableV2;
