import {
  Table,
  ScrollArea,
  Group,
  Text,
  Image,
  ActionIcon,
  Button,
  Grid,
  Autocomplete,
  Select,
} from "@mantine/core";
import { find, isEmpty, map, trim, uniq, uniqBy } from "lodash";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { amzServices } from "../../../services";
import { BRIEF_VALUES } from "../../../constant";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../../utils";

const SearchSKU = ({ close }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  const handleSearch = async () => {
    try {
      const response = await amzServices.fetchSKUs({
        limit: 20,
        page: 1,
        ...(searchValue && {
          sku: searchValue,
        }),
      });
      const { data } = response;

      setSearchResult(data);
    } catch (error) {
      console.error(error);
    }
  };

  const createBatchRequest = async () => {
    setLoading(true);
    const payloads = map(selectValue, (x) => ({
      ...(value && { value }),
      sku: x?.sellerSku,
      asin: x?.asin,
      image: x?.image,
      store: "QZL",
      markOptimized: 1,
    }));
    const response = await amzServices.createMarkOptimizedRequest({
      payloads,
    });
    setLoading(false);
    if (response) {
      close();
    }
  };

  return (
    <Grid>
      <Grid.Col span={6}>
        <Autocomplete
          placeholder="Search SKU"
          data={uniq(map(searchResult, "sellerSku"))}
          withScrollArea={true}
          defaultDropdownOpened={false}
          maxDropdownHeight={100}
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              const value = trim(event.target.value);
              if (value) {
                setSelectValue((prev) =>
                  uniqBy(
                    [find(searchResult, { sellerSku: value }), ...prev],
                    "sellerSku"
                  )
                );
              }
            }
          }}
          onOptionSubmit={(value) => {
            setSelectValue((prev) =>
              uniqBy(
                [find(searchResult, { sellerSku: value }), ...prev],
                "sellerSku"
              )
            );
          }}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          placeholder="Value"
          data={BRIEF_VALUES}
          required
          value={CONVERT_NUMBER_TO_STATUS[value]}
          onChange={(value) => setValue(CONVERT_STATUS_TO_NUMBER[value])}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        {!isEmpty(selectValue) && (
          <PreviewTable data={selectValue} setData={setSelectValue} />
        )}
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={createBatchRequest} loading={loading}>
          Request
        </Button>
      </Grid.Col>
    </Grid>
  );
};

const PreviewTable = ({ data, setData }) => {
  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const rows = map(data, (item) => {
    return (
      <Table.Tr key={item?.id}>
        <Table.Td>
          <Group gap="sm">
            <Image
              src={item?.image || "/images/content/not_found_2.jpg"}
              alt={item?.name}
              width="70px"
              height="70px"
              fit="cover"
            />
            <Text size="sm" fw={500}>
              {item?.sellerSku}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Text size="sm" fw={500}>
            {item?.asin}
          </Text>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Text size="sm" fw={500}>
            {item?.store}
          </Text>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionIcon
              variant="filled"
              aria-label="Settings"
              color="red"
              onClick={() => {
                setData((prev) => prev.filter((i) => i?.sku !== item?.sku));
              }}
            >
              <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <ScrollArea>
      <Table miw={500} verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>SKU</Table.Th>
            <Table.Th style={headCellStyles}>ASIN</Table.Th>
            <Table.Th style={headCellStyles}>Store</Table.Th>
            <Table.Th style={headCellStyles}>Remove</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default SearchSKU;
